import { connect } from 'react-redux';
import { fetchRentalProducts } from './actions/api';
import App from './App';

const mapStateToProps = (state) => ({
    onlineStore: state.onlineStore,
    utils: state.utils
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRentalProducts: () => {
      dispatch(fetchRentalProducts());
    }
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(App);