import React from 'react'
import localization from '../localization/localization'
import { NavLink } from "react-router-dom"
import './Footer.css'


const Footer = () => {
  return (
    <footer className="footer-body">
      <div className="footer-inside-container">
        <div className="footer-left">
          <ul>
            <li><NavLink to="/">{localization.home.footer.home}</NavLink></li>
            <li><NavLink to="/shop">{localization.home.footer.shop}</NavLink></li>
            <li><NavLink to="/contact">{localization.home.footer.contact}</NavLink></li>
            <li><NavLink to="/cart">{localization.home.footer.cart}</NavLink></li>
          </ul>
        </div>
        <div className="footer-right">      
          <h3>{localization.home.footer.followus}</h3>
          <ul>
            <li><a href="https://www.instagram.com/meriteijobikepark/">Instagram</a></li>
            <li><a href="https://www.facebook.com/MeriTeijoBikePark/">Facebook</a></li>
            <li><a href="https://www.youtube.com/channel/UCgRQGD2HRGvPGC1Ra_A9p1Q">Youtube</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">{localization.home.footer.copyright}</div>
    </footer>
  );
}

export default Footer;