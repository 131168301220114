import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.css'
import localization from '../localization/localization';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false
    }
    this.resizeHeaderOnScroll = this.resizeHeaderOnScroll.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 1;
    const headerElement = document.getElementById('header');
    if (distanceY > shrinkOn) {
      headerElement.classList.add('header-smaller');
    } else if (!this.getAllowedLocation()) {
      headerElement.classList.remove('header-smaller');
    }
  }

  getAllowedLocation() {
    const { location } = this.props;
    const locations = ['/shop', '/cart', '/contact', '/trails', '/openingtimes', '/events', '/partners', '/downhill', '/enduro', '/crosscountry', '/rental', '/info'];
    return locations.includes(location)
  }

  openDropdown(e) {
    this.setState({
      openDropdown: e.target.id === 'logo'  ? false : !this.state.openDropdown
    })
  }

  render() {
    const { onlineStore: { basket }, setLanguage, utils } = this.props;
    const { openDropdown } = this.state;
    return (
      <div id="header" className={this.getAllowedLocation() ? 'header-body header-smaller' : 'header-body'}>
        <NavLink exact='true' onClick={this.openDropdown} to="/">
          <div className="header-logo">
            <img id="logo" alt="mtbpLogo" src={process.env.PUBLIC_URL + '/media/MTBPLogoWhite.png'} />
            <img id="logo" alt="mtbpLogo_black" src={process.env.PUBLIC_URL + '/media/MTBPLogoWhite.png'} />
          </div>
        </NavLink>
        <div className={openDropdown ? 'header-button-container responsive' : 'header-button-container'}>
          <button className="header-dropdown-btn" onClick={this.openDropdown}>
            <FontAwesomeIcon icon="bars"/>
          </button>
          <NavLink exact='true' onClick={this.openDropdown} to="/shop">{localization.shop.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/trails">{localization.trails.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/partners">{localization.partners.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/events">{localization.events.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/openingtimes">{localization.openingtimes.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/info">{localization.info.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} to="/contact">{localization.contact.navbartitle}</NavLink>
          <NavLink exact='true' onClick={this.openDropdown} className="header-basket-icon" to="/cart">
            <FontAwesomeIcon icon="shopping-cart"/>
            { basket.count.items !== 0 ?
              <i className="header-basket-icon-count">
                <p>{basket.count.items}</p>
              </i> : null
            }
          </NavLink>
          <a className="header-localization-container" >
            <p className={utils.language === 'fi' ? 'active' : '' } onClick={() => setLanguage('fi')}>FI</p>|
            <p className={utils.language === 'eng' ? 'active' : '' } onClick={() => setLanguage('eng')}>ENG</p>
          </a>
          <a href="https://www.meriteijo.fi">{localization.partners.meriteijoski}
          </a>
        </div>
      </div>
    );
  }
}