import { connect } from 'react-redux';
import { setLanguage } from '../../actions/utils';
import Header from './Header';

const mapStateToProps = (state) => {
  return {
    onlineStore: state.onlineStore,
    utils: state.utils
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => {
      dispatch(setLanguage(language));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);