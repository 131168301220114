import {
  SET_NEW_STORE_ITEM,
  SET_REMOVE_STORE_ITEM,
  SET_REDIRECT_URL,
  SET_CONTACT_INFO,
  SET_EMPTY_STORE,
  SET_RESERVATION,
} from "../actions/actionTypes";

const initialState = {
  basket: {
    count: {
      items: 0,
      sum: 0,
    },
    products: [],
    redirect: null,
    reservation: {
      startTime: null,
      endTime: null,
      duration: null,
    },
  },
  contactInfo: [],
};

const calculateItemsSum = (products) => {
  return products.reduce((accu, product) => {
    return accu + product.price * product.amount;
  }, 0);
};

const calculateItemsCount = (products) => {
  return products.reduce((accu, product) => {
    return accu + product.amount;
  }, 0);
}

export function onlineStore(state = initialState, action) {
  switch (action.type) {
    case SET_NEW_STORE_ITEM:
      const addProducts = state.basket.products.find(
        (x) => x.id === action.item.id
      )
        ? state.basket.products.map((product) => {
            return product.id === action.item.id
              ? {
                  ...product,
                  amount: product.amount + action.item.amount,
                }
              : product;
          })
        : [...state.basket.products, ...[action.item]];

      return {
        ...state,
        basket: {
          ...state.basket,
          count: {
            items: calculateItemsCount(addProducts),
            sum: calculateItemsSum(addProducts),
          },
          products: addProducts,
        },
      };

    case SET_REMOVE_STORE_ITEM:
      const removeProducts = state.basket.products.some(
        (x) => x.id === action.item.id && x.amount !== 1
      )
        ? state.basket.products.map((product) => {
            return product.id === action.item.id
              ? {
                  ...product,
                  amount: product.amount - action.item.amount,
                }
              : product;
          })
        : state.basket.products.filter((p) => p.id !== action.item.id);

      return {
        ...state,
        basket: {
          ...state.basket,
          count: {
            items: calculateItemsCount(removeProducts),
            sum: calculateItemsSum(removeProducts),
          },
          products: removeProducts,
        },
      };

    case SET_REDIRECT_URL:
      return {
        ...state,
        basket: {
          ...state.basket,
          redirect: action.url,
        },
      };

    case SET_CONTACT_INFO:
      return {
        ...state,
        basket: {
          ...state.basket,
        },
        contactInfo: action.contact,
      };

    case SET_EMPTY_STORE:
      return {
        basket: {
          count: {
            items: 0,
            sum: 0,
          },
          products: [],
          redirect: null,
        },
        contactInfo: [],
      };

    case SET_RESERVATION:
      return {
        ...state,
        basket: {
          ...state.basket,
          reservation: {
            reservationToken: action.data.reservationToken,
            startTime: action.data.startTime,
            endTime: action.data.endTime,
            duration: action.data.duration,
          },
        },
      };

    default:
      return state;
  }
}
