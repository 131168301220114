import React from 'react';
import localization from '../localization/localization';
import './AboutUsComponent.css'

const AboutUsComponent = () => {
  return (
    <div id="about-us" className="about-us-body">
      <div className="about-us-row">
        <div className="about-us-row-column">
          <div className="about-us-column-title">{localization.home.aboutus.lowertitle}</div>
          <div className="about-us-column-text">{localization.home.aboutus.text}</div>
        </div>
        <div className="about-us-row-column">
          <img className="about-us-column-img" alt="bgi" src={process.env.PUBLIC_URL + '/media/tarinamme.jpeg'}></img>
        </div>
      </div>
    </div>
  );
}

export default AboutUsComponent;