import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import localization from "../localization/localization";
import queryString from "query-string";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryStr = queryString.parse(location.search);

  useEffect(() => {
    if (!queryStr.stamp) {
      navigate("/");
    }
  }, [navigate, queryStr.stamp]);

  return (
    <div className="payment-success-body">
      <div className="payment-success-container">
        <div className="payment-success-header">
          <h2>Maksu peruutettu</h2>
        </div>
        <div className="payment-success-content">
          <h3>Taisit peruuttaa tilauksen?</h3>
          <h4 style={{ marginTop: 10 }}>
            Mikäli peruutit tilauksen vahingossa voit tehdä uuden
            verkkokaupassamme.
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
            }}
          >
            <NavLink className="payment-success-go-to-index" to="/">
              {localization.basket.basketSuccess.returnButton}
            </NavLink>
            <NavLink className="payment-success-go-to-index" to="/shop">
              Verkkokauppaan {">"}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancel;
