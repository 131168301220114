import React, { useEffect } from "react";

const RedirectOnServer = ({ redirectUrl }) => {
  useEffect(() => {
    window.location.replace(redirectUrl);
  }, [redirectUrl]);

  return (
    <h1 
      style={{
        textAlign: 'center',
        color: '#fff',
        paddingTop: '50px'
      }}
    >
      Siirrytään palveluntarjoajan sivulle...
    </h1>
  );
};

export default RedirectOnServer;