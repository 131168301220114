import React, { useEffect, useState } from "react";
import localization from "../localization/localization";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import "./CardInfoComponent.css";

const CardInfoComponent = () => {
  const [state, setState] = useState({
    textObj: {},
  });

  const location = useLocation();
  library.add(faMapMarkedAlt);

  useEffect(() => {
    const path = location.pathname.replace("/", "");
    setState({
      textObj: {
        title: localization.home.services[path].readMoreTitle,
        readMore: localization.home.services[path].readMore,
        trailmap: localization.home.services[path].trailmap,
        shoplink: localization.home.services[path].shoplink,
        img: `${process.env.PUBLIC_URL}/media/${path}_img.jpg`,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="card-info" className="card-info-body">
      <div className="card-info-row">
        <div className="card-info-row-column">
          <div className="card-info-column-title">
            {state.textObj.title}
          </div>
          <div className="card-info-column-text">
            {state.textObj.readMore}
          </div>
          <div className="card-info-column-text">
            {state.textObj.trailmap}
            <NavLink
              exact
              className="card-info-column-shoplink"
              to="/trails"
            >
              <FontAwesomeIcon icon="map-marked-alt" />
            </NavLink>
          </div>
          <div className="card-info-column-text">
            {state.textObj.shoplink}
            <NavLink
              exact
              className="card-info-column-shoplink"
              to="/shop"
            >
              <FontAwesomeIcon icon="shopping-cart" />
            </NavLink>
          </div>
        </div>
        <div className="card-info-row-column">
          <img
            className="card-info-column-img"
            alt="bgi"
            src={state.textObj.img}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default CardInfoComponent;
