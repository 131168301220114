import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterDateFns';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { reserveRentalProduct } from '../../api/api';
import { addHoursToDate } from '../../helpers/helpers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const ProductSelectionRow = styled('div')({
  display: 'flex',
  paddingTop: 10
});

const ChipListItem = styled('li')(({ theme }) => ({
  margin: 4,
}));

const useStyles = makeStyles({
  productRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0
  },
  sizeSelect: {
    minWidth: 80,
    marginRight: 8
  }
});

const durations = [
  {
    value: 2,
    label: "2 tuntia"
  },
  {
    value: 4,
    label: "4 tuntia"
  },
  {
    value: 24,
    label: "1 päivä"
  },
  {
    value: 48,
    label: "2 päivää"
  }
];

const RentalDialog = ({ rentalDialog, rentalProducts, handleClose, addProductToBasket, language, setReservation }) => {
  const fullScreen = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  const selectedProduct = rentalProducts.find(product => product.id === rentalDialog.id);

  const id = uuidv4();
  

  const [state, setState] = useState({
    id: id,
    title: selectedProduct.name[language],
    duration: null,
    date: new Date(),
    startTime: null,
    endTime: null,
    productId: null,
    productPropertyId: null,
    size: null,
    price: null
  });

  const handleInputChange = (data) => setState({ ...state, ...data });

  const handleProductDetailChange = (size) => {
    const selectedProperty = selectedProduct.properties.find(property => property.size === size);
    setState({ 
      ...state, 
      ...{
        productId: selectedProperty.product_id,
        productPropertyId: selectedProperty.id,
        price: selectedProperty.price,
        vat: selectedProperty.vat,
        size,
      } 
    });
  };

  const getDurationValueByKey = (key, value) => {
    const d = durations.find(x => x[key] === value)
    return d ? d[key === 'label' ? 'value' : 'label'] : '';
  }

  const addToCart = async () => {
    const startDate = state.date.setHours(state.startTime, 0, 0, 0);
    const endDate = addHoursToDate(new Date(startDate), state.duration).getTime()
    await reserveRentalProduct({
      productId: state.productId,
      productPropertyId: state.productPropertyId
    }).then((res) => {
      addProductToBasket({
        ...state,
        amount: 1,
        type: 'RENTAL',
        sum: state.price,
        endTime: endDate,
        startTime: startDate
      });
      
      setReservation({
        reservationToken: res.data.token,
        startTime: startDate,
        endTime: endDate,
        duration: state.duration
      });

    });
    handleClose();
  };
  
  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={rentalDialog.open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {/* {selectedProduct.name[language]} */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              component='img'
              sx={{
                maxHeight: { xs: 220, md: 300 }
              }}
              alt={selectedProduct.img}
              src={process.env.PUBLIC_URL.concat('/media/', selectedProduct.img)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography 
              variant="h4" 
              component="div"
              sx={{ mb: 2 }}
            >
              {selectedProduct.name[language]}
            </Typography>
            <Typography sx={{ color: 'gray' }} gutterBottom>
              {selectedProduct.description[language]}
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            
            <FormControl fullWidth component="fieldset">
              <FormControlLabel
                classes={{root: classes.productRowContainer}}
                label={selectedProduct.name[language]}
                labelPlacement="start"
                control={
                  <ProductSelectionRow>
                    <TextField
                      size="small"
                      className={classes.sizeSelect}
                      label="Koko"
                      variant="outlined"
                      select
                      SelectProps={{
                        value: state.size || '',
                        onChange: (e) => handleProductDetailChange(e.target.value)
                      }}
                    >
                      {selectedProduct.properties.map((property, i) => 
                        <MenuItem key={i} value={property.size}>{property.size}</MenuItem>
                      )}
                    </TextField>
                  </ProductSelectionRow>
                }
              />
            </FormControl>
            
            <Divider sx={{ mt: 2, mb: 2 }} />

            <FormControl 
              disabled={!state.size}
              fullWidth
            >
              <InputLabel id="checkbox-label">Valitse kesto</InputLabel>
              <Select
                labelId="checkbox-label"
                value={getDurationValueByKey('value', state.duration)}
                onChange={(e) => handleInputChange({ duration: getDurationValueByKey('label', e.target.value) })}
                input={<OutlinedInput label="Valitse kesto" />}
                renderValue={(selected) => selected}
              >
                {durations.map((duration, i) => (
                  <MenuItem key={i} value={duration.label}>
                    <ListItemText primary={duration.label} />
                    <ListItemText
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                      primary={`${state.price * (i + 1)}€`} 
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Divider sx={{ mt: 2, mb: 2 }} />
            
            <Typography>Päivä</Typography>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={state.date}
                onChange={(e) => handleInputChange({ date: e })}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            
            <Typography>Aloitusaika</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {[9, 10, 11, 12, 13, 14, 15, 16].map((time) => {
                return (
                  <ChipListItem key={time}>
                    <Chip
                      label={`${time}:00`}
                      variant={state.startTime === time ? "filled" : "outlined"}
                      color={state.startTime === time ? "primary" : "default"}
                      onClick={() => handleInputChange({ startTime: time })}
                    />
                  </ChipListItem>
                );
              })}
            </Box>

          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!(!!state.duration && !!state.date && !!state.startTime && !!state.productId)}
          autoFocus 
          onClick={addToCart}
        >
          Lisää ostoskoriin
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default RentalDialog;