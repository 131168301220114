import React from "react";
import "./InputCounter.css";

const InputCounter = (props) => {
  const { product, addOnlineStoreItem, removeStoreItem, canDelete } = props;
  return (
    <div className="input-counter">
      <button
        disabled={!canDelete && product.amount <= 1}
        onClick={() => removeStoreItem({ ...product, amount: 1 })}
        className="input-minus"
      >
        -
      </button>
      <label>{product ? product.amount : 0}</label>
      <button
        onClick={() => addOnlineStoreItem({ ...product, amount: 1 })}
        className="input-plus"
      >
        +
      </button>
    </div>
  );
};

export default InputCounter;
