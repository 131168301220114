import { getStoreItemByProductType } from './../shopHelper/shopHelper';
import {
  SET_NEW_STORE_ITEM,
  SET_REMOVE_STORE_ITEM,
  SET_REDIRECT_URL,
  SET_CONTACT_INFO,
  SET_EMPTY_STORE,
  SET_RESERVATION
} from './actionTypes';

export const addStoreItem = (product) => ({
  type: SET_NEW_STORE_ITEM,
  item: getStoreItemByProductType(product, 1)
});

export const addOnlineStoreItem = (product) => ({
  type: SET_NEW_STORE_ITEM,
  item: product
});

export const addProductToBasket = (product) => ({
  type: SET_NEW_STORE_ITEM,
  item: product
});

export const removeStoreItem = (product) => ({
  type: SET_REMOVE_STORE_ITEM,
  item: product
});

export const setRedirectUrl = (url) => ({
  type: SET_REDIRECT_URL,
  url
});

export const setContactInfo = (contact) => ({
  type: SET_CONTACT_INFO,
  contact
});

export const emptyStore = () => ({
  type: SET_EMPTY_STORE,
});

export const setReservation = (data) => ({
  type: SET_RESERVATION,
  data
});