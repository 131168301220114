import React, { useState } from "react";
import localization from "../localization/localization";
import "./ShopListItem.css";
import ShopPopUp from "./ShopPopUp";

const ShopTrainingListItem = (props) => {
  const { product, addStoreItem, removeStoreItem, disabled } = props;
  const [showPopup, togglePopup] = useState(false);

  const addBasketItem = (show) => {
    addStoreItem(product.id);
    togglePopup(show);
  };

  return (
    <div
      className={
        disabled ? "shop-list-column disabled" : "trainings-list-column"
      }
    >
      <div className="shop-list-column-img-container">
        <img
          alt="shop-list-column-img"
          className="shop-list-column-img"
          src={process.env.PUBLIC_URL + product.img}
        ></img>
        {disabled && (
          <p className="rental-list-no-sale">
            {localization.shop.products.Trainings.notForSale}
          </p>
        )}
      </div>
      <div className="trainings-list-column-title">{product.title}</div>
      <div className="trainings-list-column-text">{product.description}</div>
      <div className="trainings-list-form">
        <p className="trainings-list-column-price">
          {localization.shop.products.Trainings.price} {product.price}€
        </p>
        <button
          disabled={disabled}
          onClick={() => addBasketItem(true)}
          className="shop-list-column-button"
        >
          {localization.shop.products.Trainings.button}
        </button>
      </div>
      {showPopup ? (
        <ShopPopUp
          onlineStore={props.onlineStore}
          product={product}
          closePopup={togglePopup}
          addStoreItem={addStoreItem}
          removeStoreItem={removeStoreItem}
        />
      ) : null}
    </div>
  );
};

export default ShopTrainingListItem;
