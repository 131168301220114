import React, { Component } from 'react';
import localization from '../localization/localization';
import './Partners.css';

export default class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bikeshopImg: process.env.PUBLIC_URL + '/media/bikeshop_logo.jpg',
      hhcmbImg: process.env.PUBLIC_URL + '/media/habanero_logo.jpg',
      meriteijoImg: process.env.PUBLIC_URL + '/media/skiaction_logo.jpg',
      fillaritalliImg: process.env.PUBLIC_URL + 'media/FillaritalliPromo.jpg',
      pusscampImg: process.env.PUBLIC_URL + 'media/pusscamp.jpg'
    }
  }

  render() {
    return (
      <div className="partners-body">
        <div className="partners-row">
          <div className="partners-row-column">
            <div className="partners-column-title">{localization.partners.partner1.title}</div>
            <div className="partners-column-secondtitle">{localization.partners.partner1.secondtitle}</div>
            <div className="partners-column-text">{localization.partners.partner1.description}</div>
            <a className="partners-column-link" href="https://www.bikeshop.fi/">{localization.partners.partner1.link}</a>
          </div>
          <div className="partners-row-column">
            <img className="partners-column-img" alt="bgi" src={this.state.bikeshopImg}></img>
          </div>
        </div>
        <div className="partners-row">
          <div className="partners-row-column">
            <div className="partners-column-title">{localization.partners.partner3.title}</div>
            <div className="partners-column-secondtitle">{localization.partners.partner3.secondtitle}</div>
            <div className="partners-column-text">{localization.partners.partner3.description}</div>
            <a className="partners-column-link" href="https://www.meriteijo.fi/">{localization.partners.partner3.link}</a>
          </div>
          <div className="partners-row-column">
            <img className="partners-column-img" alt="bgi" src={this.state.meriteijoImg}></img>
          </div>
        </div>
        <div className="partners-row">
          <div className="partners-row-column">
            <div className="partners-column-title">{localization.partners.partner5.title}</div>
            <div className="partners-column-secondtitle">{localization.partners.partner5.secondtitle}</div>
            <div className="partners-column-text">{localization.partners.partner5.description}</div>
            <a className="partners-column-link" href= "https://www.pusscamp.com/">{localization.partners.partner5.link}</a>
          </div>
          <div className="partners-row-column">
            <img className="partners-column-img" alt="bgi" src={this.state.pusscampImg}></img>
          </div>
        </div>
        <div className="partners-row">
          <div className="partners-row-column">
            <div className="partners-column-title">{localization.partners.partner4.title}</div>
            <div className="partners-column-secondtitle">{localization.partners.partner4.secondtitle}</div>
            <div className="partners-column-text">{localization.partners.partner4.description}</div>
            <a className="partners-column-link" href= "https://www.fillaritalli.fi">{localization.partners.partner4.link}</a>
          </div>
          <div className="partners-row-column">
            <img className="partners-column-img" alt="bgi" src={this.state.fillaritalliImg}></img>
          </div>
        </div>
        <div className="partners-row">
          <div className="partners-row-column">
            <div className="partners-column-title">{localization.partners.partner2.title}</div>
            <div className="partners-column-secondtitle">{localization.partners.partner2.secondtitle}</div>
            <div className="partners-column-text">{localization.partners.partner2.description}</div>
            <a className="partners-column-link" href= "http://www.hhcmb.fi/">{localization.partners.partner2.link}</a>
          </div>
          <div className="partners-row-column">
            <img className="partners-column-img" alt="bgi" src={this.state.hhcmbImg}></img>
          </div>
        </div>
        
      </div>
    );
  }
}