import React, { Component } from 'react'
import localization from '../localization/localization'
import './Openingtimes.css'
import Weekdays from './Weekdays'
import {weekdayData} from './WeekdayData'

export default class Openingtimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openingtimeBackground: process.env.PUBLIC_URL + '/media/Aukiolot.jpg',
    }
  }

  render() {
    const weekDay = weekdayData(localization).map(item => <Weekdays key={item.id} item={item}/>)
    return (
      <div className="openingtimes-body">
        <div className="openingtimes-background-img" style={{backgroundImage: `url(${this.state.openingtimeBackground})`}}></div>
          <div className="openingtimes-body-container">
            <div className="openingtimes-infobox">
              <h1>{localization.openingtimes.title}</h1>
              <ul>
                <li>
                  {weekDay}
                </li>
              </ul>
            </div>
          </div>
      </div>
    );
  }
}