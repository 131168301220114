import * as api from '../api/api'
import {
  FETCH_RENTAL_PRODUCTS_REQUEST,
  FETCH_RENTAL_PRODUCTS_SUCCESS,
  FETCH_RENTAL_PRODUCTS_ERROR,
} from './actionTypes';

export const fetchRentalProducts = () => {
  return async (dispach) => {
    dispach({
      type: FETCH_RENTAL_PRODUCTS_REQUEST
    });
    try {
      const response = await api.getRentalProducts();
      dispach({
        type: FETCH_RENTAL_PRODUCTS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispach({
        type: FETCH_RENTAL_PRODUCTS_ERROR,
        error: error
      });
    }
  };
};