import React, { Component } from 'react'
import localization from '../localization/localization'
import MapComponent from '../map/MapComponent'
import './ContactUsComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRestroom, faFaucet, faDungeon, faCampground } from '@fortawesome/free-solid-svg-icons'

export default class ContactUsComponent extends Component {
  constructor(props) {
    super(props)
    library.add(faRestroom, faFaucet, faDungeon, faCampground)
    this.state = {
      mapLatLon: [60.234598, 22.932896],
      mapZoom: 15,
      markers: [{
        latLon: [60.233798, 22.9414],
        size: [30, 42],
        popup: localization.contact.mapmarkers.parking,
        smallerMap: false,
        icon: 'parking',
        color: 'rgba(0, 131, 143, 1)'
      },{
        latLon: [60.234998, 22.940574],
        size: [30, 42],
        popup: localization.contact.mapmarkers.parking,
        smallerMap: false,
        icon: 'parking',
        color: 'rgba(0, 131, 143, 1)'
      },{
        latLon: [60.234198, 22.94060],
        size: [30, 42],
        popup: localization.contact.mapmarkers.fireplace,
        smallerMap: false,
        icon: 'fire',
        color: 'rgba(255, 186, 57, 1)'
      },{
        latLon: [60.236599, 22.93400],
        size: [30, 42],
        popup: localization.contact.mapmarkers.lift,
        smallerMap: false,
        icon: 'map-marker-alt',
        color: 'rgba(255, 186, 57, 1)'
      },{
        latLon: [60.232238, 22.93940],
        size: [30, 42],
        popup: localization.contact.mapmarkers.entrance,
        smallerMap: false,
        icon: 'dungeon',
        color: 'rgba(255, 186, 57, 1)'
      },{
        latLon: [60.235358, 22.93920],
        size: [30, 42],
        popup: localization.contact.mapmarkers.wash,
        smallerMap: false,
        icon: 'faucet',
        color: 'rgba(0, 131, 143, 1)'
      },{
        latLon: [60.236758, 22.93340],
        size: [30, 42],
        popup: localization.contact.mapmarkers.toilet,
        smallerMap: false,
        icon: 'restroom',
        color: 'rgba(0, 131, 143, 1)'
      },{
        latLon: [60.235128, 22.93980],
        size: [30, 42],
        popup: localization.contact.mapmarkers.reception,
        smallerMap: false,
        icon: 'home',
        color: 'rgba(255, 186, 57, 1)'
      },{
        latLon: [60.223198, 22.965974],
        size: [30, 42],
        popup: localization.contact.mapmarkers.endal,
        smallerMap: false,
        icon: 'campground',
        color: 'white'
      },{
        latLon: [60.218398, 22.958574],
        size: [30, 42],
        popup: localization.contact.mapmarkers.teerisaari,
        smallerMap: false,
        icon: 'campground',
        color: 'white'
      },{
        latLon: [60.247398, 22.983974],
        size: [30, 42],
        popup: localization.contact.mapmarkers.kalasuntti,
        smallerMap: false,
        icon: 'campground',
        color: 'white'
      },{
        latLon: [60.216198, 22.933374],
        size: [30, 42],
        popup: localization.contact.mapmarkers.vicksback,
        smallerMap: false,
        icon: 'campground',
        color: 'white'
      },]
    }
  }

  render() {
    const { mapLatLon, markers, mapZoom, smallerMap } = this.state
    return (
      <div className="contact-body">
        <div className="contact-info">
          <div className="contact-map">
            <MapComponent 
              mapLatLon={mapLatLon}
              mapZoom={mapZoom}
              markers={markers}
            />
          </div>
          <div className={`contact-us ${smallerMap ? 'smaller' : ''}`}>
            <button onClick={() => this.setState({smallerMap: !this.state.smallerMap})} className={`contact-us-hide-btn ${smallerMap ? 'smaller' : ''}`}>
              <FontAwesomeIcon icon={ smallerMap ? "chevron-up" : "chevron-down" } />
            </button>
            <ul>
              <li>
                <FontAwesomeIcon className="contact-icon" icon="map-marker-alt"/>
                <p className="contact-us-list-title">{localization.contact.box.location.title}</p>
                <a href="https://www.google.com/maps/place/Teijontie+345,+25570+Salo/@60.2323135,22.9372201,17z/data=!3m1!4b1!4m5!3m4!1s0x468c5b044aa8aa63:0x541f7c9005a1c642!8m2!3d60.2323135!4d22.9394088">{localization.contact.box.location.description}</a>
              </li>
              <li>
                <FontAwesomeIcon className="contact-icon" icon="comments" />
                <p className="contact-us-list-title">{localization.contact.box.phone.title}</p>
                <p>{localization.contact.box.phone.valtteri}</p>
                <p>{localization.contact.box.phone.jani}</p>
              </li>
              <li>
                <FontAwesomeIcon className="contact-icon" icon="envelope" />
                <p className="contact-us-list-title">{localization.contact.box.email.title}</p>
                <a href="mailto:asiakaspalvelu@meriteijobikepark.com?Subject=" target="_top">{localization.contact.box.email.description}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}