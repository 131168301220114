import React from "react";
import localization from "../localization/localization";
import InputCounter from "./InputCounter";
import "./CartItemList.css";
import { Button } from "@mui/material";
import { centsToEur } from "../../helpers/helpers";

const CartItemList = (props) => {
  const { onlineStore, addOnlineStoreItem, removeStoreItem } = props;
  console.log(onlineStore.basket.count);
  const basketItems = onlineStore.basket.products.map((product, index) => (
    <ProductItem
      key={index}
      product={product}
      addOnlineStoreItem={addOnlineStoreItem}
      removeStoreItem={removeStoreItem}
    />
  ));

  return (
    <div className="item-body">
      <table>
        <thead>
          <tr>
            <th>{localization.basket.basketcontent.product}</th>
            <th>{localization.basket.basketcontent.price}</th>
            <th>{localization.basket.basketcontent.count}</th>
            <th>{localization.basket.basketcontent.total}</th>
          </tr>
        </thead>
        <tbody>{basketItems}</tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td
              data-label={
                onlineStore.basket.count.sum > 0
                  ? localization.basket.basketcontent.total
                  : localization.basket.basketcontent.emptyBasket
              }
            >
              {centsToEur(onlineStore.basket.count.sum)} EUR
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const ProductItem = (props) => (
  <tr>
    <td data-label={localization.basket.basketcontent.product}>
      {`${props.product.title} ${
        props.product.type === "RENTAL" ? props.product.size : ""
      }`}
    </td>
    <td
      data-label={localization.basket.basketcontent.price}
    >{`${centsToEur(props.product.price)} EUR`}</td>
    <td data-label={localization.basket.basketcontent.count}>
      {props.product.type !== "RENTAL" ? (
        <InputCounter
          addOnlineStoreItem={props.addOnlineStoreItem}
          removeStoreItem={props.removeStoreItem}
          product={props.product}
          canDelete
        />
      ) : (
        <Button style={{ float: "none" }}>poista</Button>
      )}
    </td>
    <td data-label="">{`${centsToEur(props.product.price) * props.product.amount} EUR`}</td>
  </tr>
);

export default CartItemList;
