import localization from './../components/localization/localization';

export const getStoreItemByProductType = (productType, amount) => {
    switch (productType) {
      case 'CAMPAIGN_SEASON_CARD':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Kampanja.title,
          description: localization.shop.products.Tickets.Kampanja.description,
          price: 199,
          sum: 199,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: 'media/Kausikorttikampanja3.jpg',
        };
      case 'SEASON':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Kausikortti.title,
          description: localization.shop.products.Tickets.Kausikortti.description,
          price: 299,
          sum: 299,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: "media/Kausikorttikampanja2.jpg"
        };
      case 'DAY_TICKET':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Paiva.title,
          description: localization.shop.products.Tickets.Paiva.description,
          price: 33,
          sum: 33,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: 'media/Paivalippu.jpg',
        };
      case '2HR_TICKET':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Twohour.title,
          description: localization.shop.products.Tickets.Twohour.description,
          price: 25,
          sum: 25,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: 'media/Paivalippu.jpg',
        };
      case '2DAY_TICKET':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Twodays.title,
          description: localization.shop.products.Tickets.Twodays.description,
          price: 60,
          sum: 60,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: 'media/Paivalippu.jpg',
        };
      case 'JNR_TICKET':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Tickets.Junior.title,
          description: localization.shop.products.Tickets.Junior.description,
          price: 23,
          sum: 23,
          amount: 1,
          vat: 10,
          discount: 0,
          type: 1,
          img: 'media/Paivalippu.jpg',
        };
      case 'BIKE_KONA_HONZO':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.KonaHonzo.title,
          description: localization.shop.products.Rentals.KonaHonzo.description,
          price: 25,
          sum: 25,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/KonaHonzo24.jpg"
        };
      case 'BIKE_KONA_134275':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.Kona134275.title,
          description: localization.shop.products.Rentals.Kona134275.description,
          price: 40,
          sum: 40,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/process134275.jpg"
        };
      case 'BIKE_KONA_13429':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.Kona13429.title,
          description: localization.shop.products.Rentals.Kona13429.description,
          price: 40,
          sum: 40,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/Process13429.jpg"
        };
      case 'BIKE_KONA_15327':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.Kona15327.title,
          description: localization.shop.products.Rentals.Kona15327.description,
          price: 50,
          sum: 50,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/KonaPackage.jpg"
        };
      case 'BIKE_KONA_13424':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.Kona13424.title,
          description: localization.shop.products.Rentals.Kona13424.description,
          price: 35,
          sum: 35,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/Process134275.jpg"
        };
      case 'BIKE_KONA_OPERATOR':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.KonaOperator.title,
          description: localization.shop.products.Rentals.KonaOperator.description,
          price: 40,
          sum: 40,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/Operator.jpg"
        };
      case 'FOX_TITAN_JACKET':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.FoxTitanJacket.title,
          description: localization.shop.products.Rentals.FoxTitanJacket.description,
          price: 10,
          sum: 10,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/FoxTitanSport.jpg"
        };
      case 'BELL_FULLFACE':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.BellFullFace.title,
          description: localization.shop.products.Rentals.BellFullFace.description,
          price: 7,
          sum: 7,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/bluegrassHelmet.jpg"
        };
      case 'ENDURA_HUMMVEE':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.EnduraHummvee.title,
          description: localization.shop.products.Rentals.EnduraHummvee.description,
          price: 7,
          sum: 7,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/HummveeKhaki.jpg"
        };
      case 'KONA_FLORAL_JERSEY':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.KonaFloralJersey.title,
          description: localization.shop.products.Rentals.KonaFloralJersey.description,
          price: 5,
          sum: 5,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/ridingShirt.jpg"
        };
      case 'ENDURA_KNEE':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.EnduraKnee.title,
          description: localization.shop.products.Rentals.EnduraKnee.description,
          price: 7,
          sum: 7,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/kneeJnr.jpg"
        };
      case 'ENDURA_KNEE_HARD':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.EnduraKneeHard.title,
          description: localization.shop.products.Rentals.EnduraKneeHard.description,
          price: 7,
          sum: 7,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/kneeAdult.jpg"
        };
      case 'ENDURA_ELBOW':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.EnduraElbow.title,
          description: localization.shop.products.Rentals.EnduraElbow.description,
          price: 7,
          sum: 7,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/elbowJnr.jpg"
        };
      case 'ENDURA_GLOVES':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.EnduraGloves.title,
          description: localization.shop.products.Rentals.EnduraGloves.description,
          price: 5,
          sum: 5,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/EnduraGloves.jpg"
        };
      case 'GEAR_SET':
        return {
          id: productType, 
          itemType: 'RENTAL',
          title: localization.shop.products.Rentals.GearSet.title,
          description: localization.shop.products.Rentals.GearSet.description,
          price: 20,
          sum: 20,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: "media/gearset.jpg"
        };
        case 'BEGINNER_TRAINING':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Trainings.Aloittelija.title,
          description: localization.shop.products.Trainings.Aloittelija.description,
          price: 59,
          sum: 59,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: 'media/PetraEliseHissi2.jpg',
        };
        case 'BEGINNER_TRAINING_2':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Trainings.Aloittelija2.title,
          description: localization.shop.products.Trainings.Aloittelija2.description,
          price: 59,
          sum: 59,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: 'media/PetraEliseHissi2.jpg',
        };
        case 'JUNIOR_TRAINING':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Trainings.Juniori.title,
          description: localization.shop.products.Trainings.Juniori.description,
          price: 59,
          sum: 59,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: 'media/Junior_Koulutus.jpg',
        };
        case 'ADVANCED_TRAINING':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Trainings.Kokenut.title,
          description: localization.shop.products.Trainings.Kokenut.description,
          price: 59,
          sum: 59,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: 'media/AdvancedTraining.jpg',
        };
        case 'ADVANCED_TRAINING_2':
        return {
          id: productType, 
          itemType: 'TICKET',
          title: localization.shop.products.Trainings.Kokenut2.title,
          description: localization.shop.products.Trainings.Kokenut2.description,
          price: 59,
          sum: 59,
          amount: 1,
          vat: 24,
          discount: 0,
          type: 1,
          img: 'media/Enduro_New.jpg',
        };      
      default:
        break;
    }
};


export const getPaymentMethod = (methodId) => {
  const methods = [
    {
      id: 1,
      method: 'Nordea'
    },
    {
      id: 2,
      method: 'Osuuspankki'
    },
    {
      id: 3,
      method: 'Danske Bank'
    },
    {
      id: 5,
      method: 'Ålandsbanken'
    },
    {
      id: 6,
      method: 'Handelsbanken'
    },
    {
      id: 9,
      method: 'PayPal'
    },
    {
      id: 10,
      method: 'S-Pankki'
    },
    {
      id: 11,
      method: 'Klarna Invoice'
    },
    {
      id: 12,
      method: 'Klarna Installment'
    },
    {
      id: 18,
      method: 'Jousto'
    },
    {
      id: 30,
      method: 'Visa'
    },
    {
      id: 31,
      method: 'MasterCard'
    },
    {
      id: 34,
      method: 'Diners Club'
    },
    {
      id: 35,
      method: 'JCB'
    },
    {
      id: 50,
      method: 'Aktia'
    },
    {
      id: 51,
      method: 'POP Pankki'
    },
    {
      id: 52,
      method: 'Säästöpankki'
    },
    {
      id: 53,
      method: 'Visa (Nets)'
    },
    {
      id: 54,
      method: 'MasterCard (Nets)'
    },
    {
      id: 55,
      method: 'Diners Club (Nets)'
    },
    {
      id: 56,
      method: 'American Express (Nets)'
    },
    {
      id: 58,
      method: 'MobilePay'
    },
    {
      id: 60,
      method: 'Collector Bank'
    },
    {
      id: 61,
      method: 'Oma Säästöpankki'
    }
  ];

  return methodId ? methods.filter(method => method.id === methodId).shift().method : 'Method not found';
}