import React, {useState} from 'react';
import './Conditions.css';
import localization from '../localization/localization';

const Conditions = ({handleChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openPopUp = () => setIsOpen(!isOpen);

  return (
    <div className="shop-conditions-container"> 
      <input onClick={handleChange} name="conditions" className="shop-conditions-checkbox" type="checkbox"/>
      <div className="popup">{localization.basket.basketform.deliveryTerms}
        <u onClick={openPopUp}> {localization.basket.basketform.terms}</u>
        <span className={isOpen ? "popuptext show" : "popuptext"}>
          {localization.basket.basketform.shopConditions}
        </span>
      </div>
    </div>
  );
}

export default Conditions;