import axios from 'axios';
const apiKey = process.env.REACT_APP_BE_KEY;

const createPayment = async (body) => {
  return axios.post('/api/v1.0/payment/create', body, {
    headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey
    }
  }).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  })
};

const getRentalProducts = async () => {
  return axios.get('/api/v1.0/rental/get-products', {
    headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey
    }
  }).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  })
};

const getOrderById = async (id) => {
  return axios.get(`/api/v1.0/payment/order/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey
    }
  }).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  })
};

const reserveRentalProduct = async (body) => {
  return axios.post('/api/v1.0/rental/reserve', body, {
    headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey
    }
  }).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  })
};

const orderRentalProduct = async (body) => {
  return axios.post('/api/v1.0/rental/order', body, {
    headers: {
      'Content-Type': 'application/json',
      'apikey': apiKey
    }
  }).then(response => {
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  })
}

export {
  createPayment,
  getOrderById,
  getRentalProducts,
  reserveRentalProduct,
  orderRentalProduct
}
