import React from 'react'

function Weekdays(props) {
    
    const styles = {
        color: "white",
        textAlign: "Center",
        fontSize: 19,
        paddingRight: 40,
        paddingBottom: 7,
        fontFamily: "Staatliches, sans-serif"
    }

    if (props.item.status === "0") {
        styles.color = "#E01508"
        
    } else {
        styles.color = "#0CDA04"
        styles.fontSize = 25
    } 

    return(
        <div>
        <p style={styles}>{props.item.title}{props.item.time}</p>
        </div>
    )
}

export default Weekdays;