import { connect } from 'react-redux';
import { addOnlineStoreItem, addProductToBasket, addStoreItem, removeStoreItem, setReservation } from '../../actions/onlineStore';
import Shop from './Shop';

const mapStateToProps = (state) => {
  return {
    onlineStore: state.onlineStore,
    rentalProducts: state.rentalProducts,
    language: state.utils.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addStoreItem: (product) => {
      dispatch(addStoreItem(product));
    },
    removeStoreItem: (product) => {
      dispatch(removeStoreItem(product));
    },
    addProductToBasket: (product) => {
      dispatch(addProductToBasket(product));
    },
    setReservation: (data) => {
      dispatch(setReservation(data));
    },
    addOnlineStoreItem: (product) => {
      dispatch(addOnlineStoreItem(product));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);