import React from 'react';
import ShopServiceListItem from './ShopServiceListItem';
import { getStoreItemByProductType } from '../../shopHelper/shopHelper';
import './ShopList.css';

const ShopServiceList = (props) => {
  return (
    <div className="shop-list-body">
      <div className="shop-list">
        <ShopServiceListItem 
          addStoreItem={props.addStoreItem}
          removeStoreItem={props.removeStoreItem}
          onlineStore={props.onlineStore} 
          product={getStoreItemByProductType('GUIDED_MTB_TOUR')}
          disabled={false}/>
        <ShopServiceListItem
          addStoreItem={props.addStoreItem}
          removeStoreItem={props.removeStoreItem}
          onlineStore={props.onlineStore} 
          product={getStoreItemByProductType('BIKE_PARK_INSTRUCTOR')}
          disabled={true}/>
        <ShopServiceListItem
          addStoreItem={props.addStoreItem}
          removeStoreItem={props.removeStoreItem}
          onlineStore={props.onlineStore}
          product={getStoreItemByProductType('NATIONAL_PARK_TOUR')}
          disabled={false}/>
      </div>
    </div>
  );
};

export default ShopServiceList;

