export const conditionsTextEng = 
`Javaction Oy terms of delivery valid from 20.01.2020
General information about our web store
Products in the web store sells Javaction Oy, Business ID 2995842-3
Javaction Oy sells products in Finland and EU-area. Product prices contain VAT. We reserve the right for changing prices.

Ordering
Products can be ordered via www.meriteijobikepark.com/shop
All orders get a confirmation e-mail where product prices, ordering fees and products are listed. To get the order confirmation via our web store, you need to provide us with a valid e-mail address.
During every order you will adhere to our most recent terms of delivery.
You can buy lift tickets from our web store. You get the physical ticket from us during opening times with your order confirmation receipt. Season passes are for personal use only. Season passes or tickets don't have policy for return. 

Order- and payment confirmation
We will automatically send you a confirmation via e-mail which contains:
•	Product list of your order
•	Price of your order
Payment methods
Products and their postal fees are paid during the order. You can pay via following payment methods: Nordea, Osuuspankki, Danske Bank, Ålandsbanken, Handelsbanken, S-Pankki, Aktia, Säästöpankki, POP Pankki, Oma Säästöpankki, Visa, MasterCard, American Express, Diners Club, JCB, Collector payment- and part payment, Jousto payment- and part payment, Paytrail-account. Payment happens through Paytrail Oyj website.
——————–
Maksupalveluntarjoaja
Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj www.paytrail.com (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.
Paytrail Oyj, Y-Tunnus: 2122839-7
Innova 2
Lutakonaukio 7
40100 Jyväskylä
Puhelin 0207 181830
www.paytrail.com
——————–
Payment Service Provider
Paytrail Plc acts as a collecting payment service provider and is an authorized Payment Institution. Paytrail Plc will be shown as the recipient on your bank or credit card statement. Paytrail Plc will forward the payment to the merchant. For reclamations, please contact the website the payment was made to.
Paytrail Plc
Innova 2
Lutakonaukio 7
40100 Jyväskylä
Phone +358 207 181 830
Business ID 2122839-7
——————–
Return policies
Products bought from our web store don't have policy for return.

Other
We reserve all rights to change our terms of delivery. You are required to get familiar with our most recent terms of delivery.
Register caption
This web store agrees with Javaction Oy register caption.`;