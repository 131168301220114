import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Carousel.css';

const CarouselComponent = (props) => {
  const { carouselImages } = props;
  return (
    <div className="carousel-body">
      <Carousel 
        showArrows={true} 
        showThumbs={false} 
        autoPlay={true} 
        interval={3000}
        infiniteLoop={true}
        showStatus={false}
        className="carousel-component">
        { carouselImages.map((image, index) =>
          <div key={index} className="carousel-image"> 
            <img alt="carousel-img" src={image} />
          </div>
        )}
      </Carousel>
    </div>
  );
};


export default CarouselComponent;