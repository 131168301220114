import React, { Component } from 'react'
import L from 'leaflet'
import { renderToStaticMarkup } from "react-dom/server"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free-solid'
import 'leaflet/dist/leaflet.css'
import './MapComponent.css'
  
export default class MapComponent extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.popupRef = React.createRef();
    this.state = {
      //tileUrl: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      tileUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      defaultMapLatLon: [60.236998, 22.935896],
      mapDefaultZoom: 13,
    }
    this.clickToFeature = this.clickToFeature.bind(this);
  }

  componentDidMount() {
    this.initMap();
  }

  clickToFeature(e) {
    
  }

  initMap() {
    const { tileUrl, defaultMapLatLon, mapDefaultZoom } = this.state;
    const { mapLatLon, markers, mapZoom, geoJson } = this.props;

    this.map = L.map('map', {
      center: mapLatLon || defaultMapLatLon,
      zoom: mapZoom || mapDefaultZoom,
      minZoom: 10,
      maxZoom: 17,
      layers: [
        L.tileLayer(tileUrl, {
          attribution: '© OpenStreetMap'
        })
      ]
    });

    if (geoJson) {
      geoJson.features.forEach((layer) => {
        L.layerGroup([
        L.geoJSON(layer, {
          style: {
            color: 'black',
            weight: 5.5,
            opacity: 1,
            dashArray: layer.properties.dashed && 7
          }
        }),
        
        L.geoJSON(layer, {
          style: {
            color: layer.properties.stroke,
            weight: 3.3,
            opacity: 2,
            dashArray: layer.properties.dashed && 7
          },
          onEachFeature: function onEachFeature(feature, layer) {
            layer.bindPopup('<h1>'+feature.properties.title+'</h1><p>'+feature.properties.description+'</p>');
            layer.on({
              mouseover: function(event) {
                const hoverLayer = event.target;
                hoverLayer.bringToFront();
                hoverLayer.setStyle({
                  color: feature.properties.stroke,
                  weight: 10,
                })
              },
              mouseout: function(event) {
                const hoverLayer = event.target;
                hoverLayer.setStyle({
                  color: event.target.feature.properties.stroke,
                  weight: 3.3
                })
              },
              click: (function(event){
              })
            });
          }
        })]
        ).addTo(this.map)
      });
    }
    if (markers) {
      markers.forEach((marker) => {
        const finalMrker = this.createMarker(marker.latLon, marker.icon, marker.size, marker.color);
        finalMrker.addTo(this.map);
        marker.popup && finalMrker.bindPopup(`<b>${marker.popup}</b>`);
      });
    }
  };

  createMarker(latLon, icon, size, color) {
    const marker = L.marker(latLon, {
      icon: L.divIcon({
        className: 'custom-div-icon',
        html: renderToStaticMarkup(<FontAwesomeIcon style={{color: color ? color : "white"}} icon={icon} size="3x" />),
        iconSize: size,
      })
    });
    return marker;
  }
  
  render() {
    return (
      <div id="map"/> 
    );
  };
};