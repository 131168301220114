import LocalizedStrings from 'react-localization';

import fi from './fi';
import eng from './eng';

const strings = new LocalizedStrings({
  fi,
  eng,
});

export default strings;