import React, { useEffect, useState } from "react";
import localization from "../localization/localization";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { getOrderById } from "../../api/api";
import "./PaymentSuccessful.css";
import { centsToEur } from "../../helpers/helpers";

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryStr = queryString.parse(location.search);

  const useQuery = (id) => {
    const [data, setData] = useState();
    const [error, setError] = useState();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getOrderById(id);
          setData(response.data);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    }, [id]);
    return {
      data,
      error,
    };
  };

  const { data, error } = useQuery(queryStr.stamp);

  useEffect(() => {
    if (!queryStr.stamp || !!error) {
      navigate("/");
    }
  }, [error, navigate, queryStr.stamp]);

  const checkList =
    data &&
    data.products.map((product, index) => (
      <Products key={index} product={product} />
    ));

  return data ? (
    <div className="payment-success-body">
      <div className="payment-success-container">
        <div className="payment-success-header">
          <h2>{localization.basket.basketSuccess.title}</h2>
        </div>
        <div className="payment-success-content">
          <h3>{localization.basket.basketSuccess.customer.title}</h3>
          <table className="payment-success-table">
            <tbody>
              <tr>
                <th>{localization.basket.basketSuccess.customer.firstName}</th>
                <th>{localization.basket.basketSuccess.customer.surName}</th>
                <th>{localization.basket.basketSuccess.customer.date}</th>
                <th>{localization.basket.basketSuccess.order.id}</th>
              </tr>
              <tr>
                <td>{data.firstname}</td>
                <td>{data.lastname}</td>
                <td>{new Date().toLocaleDateString()}</td>
                <td>{queryStr.stamp}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="payment-success-content">
          <h3>{localization.basket.basketSuccess.order.title}</h3>
          <table className="payment-success-table">
            <tbody>
              <tr>
                <th>{localization.basket.basketSuccess.order.product}</th>
                <th>{localization.basket.basketSuccess.order.amount}</th>
                <th>{localization.basket.basketSuccess.order.price}</th>
                <th>{"alv"}</th>
                <th>{localization.basket.basketSuccess.order.finalprice}</th>
              </tr>
              {checkList}
            </tbody>
          </table>
          <NavLink className="payment-success-go-to-index" to="/">
            {localization.basket.basketSuccess.returnButton}
          </NavLink>
        </div>
      </div>
    </div>
  ) : null;
};

const Products = ({ product }) => (
  <tr>
    <td>{product.name.fi}</td>
    <td>{product.amount} KPL</td>
    <td>{centsToEur(product.price * ((100 - product.vat) / 100)).toFixed(2)} €</td>
    <td>{product.vat} %</td>
    <td>{centsToEur(product.price) * product.amount} €</td>
  </tr>
);

export default PaymentSuccessful;
