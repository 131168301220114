// Redux action types for online store
export const SET_NEW_STORE_ITEM = 'SET_NEW_STORE_ITEM';
export const SET_REMOVE_STORE_ITEM = 'SET_REMOVE_STORE_ITEM';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
export const SET_EMPTY_STORE = 'SET_EMPTY_STORE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const FETCH_RENTAL_PRODUCTS_REQUEST = 'FETCH_RENTAL_PRODUCTS_REQUEST';
export const FETCH_RENTAL_PRODUCTS_SUCCESS = 'FETCH_RENTAL_PRODUCTS_SUCCESS';
export const FETCH_RENTAL_PRODUCTS_ERROR = 'FETCH_RENTAL_PRODUCTS_ERROR';
export const SET_RESERVATION = 'SET_RESERVATION';