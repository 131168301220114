import React, { Component } from 'react';
import localization from '../localization/localization';
import './Events.css';

export default class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      softOpening: process.env.PUBLIC_URL + '/media/hissipyoraily_img.jpg',
      openingEvent: process.env.PUBLIC_URL + '/media/Carousel5.jpg',
      warmupEvent: process.env.PUBLIC_URL + '/media/GFormEnduro.jpg',
      dhEvent: process.env.PUBLIC_URL + '/media/LegitWeekend.jpg',
      trainingEvent: process.env.PUBLIC_URL + '/media/verkkokauppa.jpg',
      legitEvent: process.env.PUBLIC_URL + '/media/LegitWeekend2022.jpg',
      friendWed: process.env.PUBLIC_URL + '/media/maastopyoraily_img.jpg',
      seasonSale: process.env.PUBLIC_URL + '/media/SeasonSale.jpg',
      ruskaRace: process.env.PUBLIC_URL + '/media/RuskaRace.jpg',
      seasonEnder: process.env.PUBLIC_URL + '/media/SeasonEnder.jpg',
      konaFreeride: process.env.PUBLIC_URL + '/media/Carousel4.jpg',
      mtbrally2022: process.env.PUBLIC_URL + '/media/Carousel1.jpg',
      mtbcrankup2022: process.env.PUBLIC_URL + '/media/crankup_downhill.jpg',
      kreisikes: process.env.PUBLIC_URL + '/media/yrityksille_img.jpg',
      mimmipaiva: process.env.PUBLIC_URL + '/media/Mimmipaiva2.jpg'
    }
  }

  render() {
    return (
      <div className="events-body">
        <div className="events-row">
        <div className="events-row-column">
          <div className="events-column-title">{localization.events.event1.title}</div>
          <div className="events-column-secondtitle">{localization.events.event1.secondtitle}</div>
          <div className="events-column-text">{localization.events.event1.description}</div>
          <a className="partners-column-link" href="">{localization.events.event1.link}</a>
        </div>
          <div className="events-row-column">
            <img className="events-column-img" alt="bgi" src={this.state.softOpening}></img>
          </div>
        </div>
        <div className="events-row">
        <div className="events-row-column">
          <div className="events-column-title">{localization.events.event2.title}</div>
          <div className="events-column-secondtitle">{localization.events.event2.secondtitle}</div>
          <div className="events-column-text">{localization.events.event2.description}</div>
          <a className="partners-column-link" href="">{localization.events.event2.link}</a>
        </div>
          <div className="events-row-column">
            <img className="events-column-img" alt="bgi" src={this.state.openingEvent}></img>
          </div>
        </div>
        <div className="events-row">
        <div className="events-row-column">
          <div className="events-column-title">{localization.events.event6.title}</div>
          <div className="events-column-secondtitle">{localization.events.event6.secondtitle}</div>
          <div className="events-column-text">{localization.events.event6.description}</div>
          <a className="partners-column-link" href="">{localization.events.event6.link}</a>
        </div>
          <div className="events-row-column">
            <img className="events-column-img" alt="bgi" src={this.state.friendWed}></img>
          </div>
        </div>
        <div className="events-row">
        <div className="events-row-column">
          <div className="events-column-title">{localization.events.event3.title}</div>
          <div className="events-column-secondtitle">{localization.events.event3.secondtitle}</div>
          <div className="events-column-text">{localization.events.event3.description}</div>
          <a className="partners-column-link" href="https://suomidh.tapahtumiin.fi/fi/">{localization.events.event3.link}</a>
        </div>
          <div className="events-row-column">
            <img className="events-column-img" alt="bgi" src={this.state.dhEvent}></img>
          </div>
        </div>
        <div className="events-row">
          <div className="events-row-column">
            <div className="events-column-title">{localization.events.event10.title}</div>
            <div className="events-column-secondtitle">{localization.events.event10.secondtitle}</div>
            <div className="events-column-text">{localization.events.event10.description}</div>
          </div>
          <div className="events-row-column">
            <img className="events-column-img" alt="bgi" src={this.state.konaFreeride}></img>
          </div>
        </div>
      </div>  
    );
  }
}