import { connect } from 'react-redux';
import { addOnlineStoreItem, removeStoreItem, setRedirectUrl, setContactInfo } from '../../actions/onlineStore';
import CartComponent from './CartComponent';

const mapStateToProps = (state) => {
  return {
    onlineStore: state.onlineStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addOnlineStoreItem: (product) => {
      dispatch(addOnlineStoreItem(product));
    },
    removeStoreItem: (product) => {
      dispatch(removeStoreItem(product));
    },
    setRedirectUrl: (url) => {
      dispatch(setRedirectUrl(url));
    },
    setContactInfo: (contact) => {
      dispatch(setContactInfo(contact));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartComponent);