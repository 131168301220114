import { SET_LANGUAGE } from '../actions/actionTypes';

const initialState = {
  language: 'fi',
};

const storage = JSON.parse(localStorage.getItem('utils'));

export const utils = (state = { ...initialState, ...storage }, action) => {
  switch (action.type) {

    case SET_LANGUAGE:
      const addLang = {
        ...state,
        language: action.language
      };
      
      localStorage.setItem('utils', JSON.stringify(addLang));
      return addLang;

    default:
      return state;
  };
};