import React from "react";
import localization from "../localization/localization";
import { NavLink } from "react-router-dom";
import InputCounter from "./../cart/InputCounter";
import "./ShopPopUp.css";
import { centsToEur } from "../../helpers/helpers";

const ShopPopUp = (props) => {
  const { product, addOnlineStoreItem, removeStoreItem, onlineStore, language } = props;

  const currentProduct = onlineStore.basket.products.find((p) => p.id === product.id)
  return (
    <div className="popup-body">
      <div className="popup-inner">
        <div className="popup-inner-header">
          <h2>{localization.shop.addItem.title}</h2>
        </div>
        <div className="popup-inner-body">
          <p>{product.name[language]}</p>
          <div className="popup-input-counter-container">
            <div className="popup-input-counter">
              <InputCounter
                addOnlineStoreItem={addOnlineStoreItem}
                removeStoreItem={removeStoreItem}
                product={currentProduct}
              />
            </div>
            <p>
              {localization.shop.addItem.total}{" "}
              {currentProduct ? centsToEur(currentProduct.price) * currentProduct.amount : 0} €
            </p>
          </div>
        </div>
        <div className="popup-footer">
          <button
            onClick={() => props.closePopup(false)}
            className="popup-footer-continue-shopping"
          >
            {"< "} {localization.shop.addItem.continueShoppingButton}
          </button>
          <NavLink className="popup-footer-go-basket" to="/cart">
            {localization.shop.addItem.toBasketButton} {" >"}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ShopPopUp;
