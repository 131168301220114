import { conditionsTextEng } from './conditionsTextEng';

const eng = {
indextitle: 'Meri-Teijo Bike Park - Biking with lift, Mountain biking, Bike & gear rental',
  home: {
  title: 'Meri-Teijo Bike Park',
  month: '21.10. Fri 11-17',
  campaign: 'Park season begins: Soft Opening saturdays 18.5. & 25.5.',
  info: {
    info: 'Events ->',
    link: 'Link to events ->',
    mtb: 'Electric Fatbikes now in our rental store! Reservations via email currently.',
    mtb2: '',
    shop: 'Link to shop ->'
  }, 
  week: 'In October',
  week2: 'Week 25 - Closed',
  wed: '',
  thu: 'After Midsummerweek:',
  fri: 'Thu-Fri - 16-20',
  sat: 'Sat-Sun 11-17',
  sun: 'We have transitioned to fall!',
    aboutus: {
    title: 'Our Story',
    lowertitle: 'Our Story',
    text: `Mountain biking lit a passion in our hearts. Our fellow shredders in Turku did not have a place to go riding downhill with lifts since there are no bike parks in Southwestern Finland. Our idea was warmly welcomed in Teijo Ski & Action, whom opened doors for this dream to come true. Meri-Teijo Bike Park was born.  \n \n  We share this creation with you and welcome you to join in our growing community. We join the growing Finnish mountain biking scene with a community, where friends do not get left behind. \n \n We help beginners to get on their saddles and challenge those more experienced with memorable elements. MTBP is made by riders, to riders.`
    },
    services: {
    title: 'Downhill and mountainbiking center next to national park',
      hissipyoraily: {
      title: 'Bike Park',
      text: 'Our park specializes in downhill. Familiar T-Bar lifts you up and ride down your pace! Park has 13 unique trails of all kinds of difficulties plus a trainee zone with carpet lift.',
      button: 'Read More',
      readMoreTitle: 'Downhill',
      readMore: 'Riding downhill is basically like skiing in summer. You hitch a ride with a lift and ride down! You can find beginner friendly and fun Flow tracks with safe jumps, wooden decks and elements without rocks and roots. Experienced riders will enjoy these tracks by adding more speed. For those who want different kind of challenge and the real DH or Freeride experience we have speedy, technical tracks with big jumps that will require you to grow as a rider. Our beginner-friendly tracks and training zone allows people new to the sport get familiar quickly and safe. Our rental shop has all the required gear and bikes to get started!\n\n',
      shoplink: 'Check out our rental bikes! ',
      trailmap: 'Check out our trail map! '  
      },
      yrityksille: {
      title: 'Companies & Groups',
      text: 'If you are planning to ride downhill, eFatbike ride to national park, teach your group how to ride bikes or just a fun activity for your friends we customize the right activities just for you!',
      button: 'Read More',
      readMoreTitle: 'Companies & Groups',
      readMore: 'If you are searching for activities for your business, group or family, you can ask more about our services and we will try our best to offer you the best activity package. We can offer variety of fun together with Ski & Action Park. You can combine mountain biking and downhill carts for example. For season 2023 we have 8 electric fatbikes in our rental shop as well! This is a great way to explore the national park. If you have more questions please contact us via mail asiakaspalvelu@meriteijobikepark.com \n\n',
      shoplink: 'Check out our rental bikes! ',
      trailmap: 'Check out our trail map! '
      },
      maastopyoraily: {
      title: 'Mountain Biking',
      text: 'One of a kind national park trail network for advanced riders and easy to access scenic routes with awesome views. Rental eFatbikes for a memorable ride!',
      button: 'Read More',
      readMoreTitle: 'Mountain Biking',
      readMore: 'We are right next to Teijo national park. This offers mountain bikers a chance to explore the beautiful nature of Southern Finland. We also offer rental services and guided trips to the national park on bikes. Rough estimate of marked trails in the national park is about 50 kilometres. Great way to explore the national park is by renting electric fatbikes from our rental shop. You can leave with a group up to 8 people currently. Lake Mathildedal and Jeturkasti can be accessed by those who have limited abilities. There is a fair amount of elevation changes on the trails, so mountain bikers can face a real challenge when on the right trails. If you are just looking for a easy route, there are several easy to access spots even with mountain bikes. \n\n',
      shoplink: 'Check out our rental bikes! ',
      trailmap: 'Check out our trail map! '
      },
      vuokraamo: {
      title: 'Rental & Services',
      text: 'Downhill or Mountainbikes, eFatbikes, safety gear, guides and lessons. Prepare with proper gear from our rental store to make sure you have a successful trip!',
      button: 'Read More',
      readMoreTitle: 'Rental & Services',
      readMore: 'We offer only the best quality gear in our rental services. If you wish to spend your day in the Bike Park or the mountainbike trails in the national park, we cannot prepare you any better than this. All of our bike models for downhill are full suspension bikes. Fatbikes are fresh Tunturi fatbikes with front suspension. If you wish to have a guide with you or want activity as a group, please, contact us! asiakaspalvelu@meriteijobikepark.com \n\n ',
      shoplink: 'Check out our rental bikes! ',
      trailmap: 'Check out our trail map! '
      }
    },

    footer: {
    home: 'Home',
    shop: 'Shop',
    contact: 'Contact',
    followus: 'Social media',
    cart: 'Shopping cart',
    copyright: '© Meri-Teijo Bike Park 2023 | Photos © Tomi Tähti / Jere Satamo / Miika Niskanen',      
    }
  },

  shop: {
  navbartitle: 'Shop',
  title: 'Welcome to the web store!',
  productTitle: 'Web Store',
  description: 'You can buy lift tickets from our web store. You get the physical ticket from us during opening times with your receipt. Season passes are for personal use only. Season passes or tickets dont have policy for return.',
  announcer: 'You can find us from service providers when paying with culture payment methods.',
    addItem: {
    title: 'Product added to cart successfully',
    total: 'Total:',
    continueShoppingButton: 'Continue Shopping',
    toBasketButton: 'Go to shopping cart'
    },
    rentalPopUp: {
    title: 'Välineet',
    amount: 'Valitse kuinka monta tarvitset',
    bike: 'Pyörä',
    size: 'Pyörän koko',
    backButton: 'Takaisin',
    nextButton: 'Seuraava', 
    total: 'Hinta Yht.'
    },
    rentalUserInfo: {
    title: 'Vuokraajan tiedot',
    gender: 'Sukupuoli',
    skill: 'Taitotaso',
    height: 'Pituus',
    weight: 'Paino',
    info: 'Lisätietoja'
    },
    products: {
    title: 'Products',
      Tickets: {
      title: 'Tickets (Under maintenance)',
      price: 'Price',
      button: 'Add to basket',
      notForSale: 'Not available',
        Kampanja: {
        title: 'Season pass sale 2023',
        description: 'Secure your place in our park this season for a reasonable price!',
        },
        Kausikortti: {
        title: 'Season pass 2023',
        description: 'Season pass allows you to ride the whole season. Our skicard is SnowCard.',
        },
        Twodays: {
        title: '2 Day ticket',
        description: 'You can buy a day ticket for yourself or as a gift! Our skicard is SnowCard.',
        },
        Paiva: {
        title: 'Day ticket',
        description: 'You can buy a day ticket for yourself or as a gift! Our skicard is SnowCard.',
        },
        Twohour: {
        title: '2h ticket',
        description: 'Do you just want to try our park? You can buy a day ticket for yourself or as a gift! Our skicard is SnowCard.',
        },
        Junior: {
        title: 'Junior Day ticket',
        description: 'Day ticket for juniors below 12. Our skicard is SnowCard.',
        },
      },
      Rentals: {
      title: 'Rental',
      price: 'Price from.',
      button: 'Make a reservation',
      notForSale: 'Not available',
      reserve: 'Email reservation only',
        Kona134275: {
        title: 'Kona Process 134 27,5"',
        description: 'Full Suspension with 134mm of travel. Excellent bike for beginners.'
        },
        Kona13429: {
        title: 'Kona Process 134 29"',
        description: 'Full Suspension with 134mm of travel. Excellent bike for beginners.'
        },
        Kona15327: {
        title: 'Kona + Full Protection Set',
        description: 'Downhill or enduro bike plus full protective gear for bundle price.'
        },
        KonaHonzo: {
        title: 'Kona Honzo 24"',
        description: 'Kids mountainbike with front suspension. We recommend it for kids 110-145cm in height.'
        },
        KonaOperator: {
        title: 'Kona Operator 27,5"',
        description: 'Full blown downhill bike with 200mm travel. This is your best take for bike park trails.'
        },
        Kona13424: {
        title: 'Kona Stinky 24"',
        description: 'Full suspension 120mm travel mtb for kids. Fits for every level and kind of riding.'
        },
        FoxTitanJacket: {
        title: 'Upper Body Armor Fox',
        description: 'Upper body protection for bike park riding.'
        },
        KonaFloralJersey: {
        title: 'Riding Shirt',
        description: 'Stylish riding shirt.'
        },
        BellFullFace: {
        title: 'Full Face Helmet',
        description: 'Full face helmet for downhill.'
        },
        EnduraHummvee: {
        title: 'Open Helmet',
        description: 'Helmet for mountain biking.'
        },
        EnduraKnee: {
        title: 'Junior Endura Knee pads',
        description: 'Knee pads for juniors.'
        },
        EnduraKneeHard: {
        title: 'Endura MT500 Knee pads',
        description: 'Adult size knee pads for bike park riding.'
        },
        EnduraElbow: {
        title: 'Junior Endura Elbow pad',
        description: 'Elbow pads for juniors.'
        },
        EnduraGloves: {
        title: 'Endura Gloves',
        description: 'Riding gloves for bike park or trail riding.'
        },
        GearSet: {
        title: 'Protective Gear Set',
        description: 'Full Face Helmet and goggles, upper body armor, knee pads, gloves & shirt.'
        }
      },
      Services: {
      title: 'Services (Under maintenance)',
      price: 'Price',
      button: 'Add to basket',
      notForSale: 'Not available',
        giftCard1: {
        title: 'Gift Card 1',
        description: 'Gift Card of 25 Euros.'
        },
        giftCard2: {
        title: 'Gift Card 2',
        description: 'Gift Card of 50 Euros.'
        },
        giftCard3: {
        title: 'Gift Card 3',
        description: 'Gift Card of 100 Euros.'
        }
      },
      Trainings: {
      title: 'Training (Under maintenance)',
      price: 'Price',
      button: 'Add to basket',
      notForSale: 'Not available',
        Aloittelija: {
        title: 'Downhill for beginners',
        description: 'ssipyöräilyn perusteita. Ryhmä on aloittelioille, kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Meri-Teijo Bike Parkin vuokraamosta saa varattua kaiken edellämainitun koulutusta varten. Varaa omasi ajoissa! Lipun hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Aloittelija2: {
        title: 'Downhill for beginners',
        description: ' jossa käydään läpi hissipyöräilyn perusteita. Ryhmä on aloittelioille, kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Meri-Teijo Bike Parkin vuokraamosta saa varattua kaiken edellämainitun koulutusta varten. Varaa omasi ajoissa! Lipun hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Kokenut: {
        title: 'Downhill - Advanced',
        description: 'Hissipyöräilyn alkeet jo hallussa? Ota ajotaitojen kehittämiselle selvä suunta. Bike Park Instructor Elise Kulmala vetää valmennuksen, jossa käydään läpi mm. hyppyjä ja droppeja. Hissinkäyttö pitää olla hallussa. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Kokenut2: {
        title: 'Downhill - Advanced',
        description: 'Hissipyöräilyn alkeet jo hallussa? Ota ajotaitojen kehittämiselle selvä suunta. Bike Park Instructor Elise Kulmala vetää valmennuksen, jossa käydään läpi mm. hyppyjä ja droppeja. Hissinkäyttö pitää olla hallussa. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Juniori: {
        title: 'Downhill for Juniors (Age 8-13)',
        description: 'Bike Park Instructor Elise Kulmala vetää valmennuksen, jossa käydään läpi hissipyöräilyn perusteita. Ryhmä on aloitteleville junioreille suunnattu valmennus. Kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Meri-Teijo Bike Parkin vuokraamosta saa varattua varusteita ja pyöriä tarpeen mukaan. Varaa omasi ajoissa! Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        }
      }
    }
  },

  contact: {
  navbartitle: 'Contact',
  title: 'Contact Information',
    mapmarkers: {
    transitionentrance: 'Transition Entrance',
    transitiontop: 'Transition Top',
    transitionlift: 'Transition Lift',
    lift: 'T-Bar Lift',
    welcoming: 'YOU CAN FIND US HERE!',
    parking: 'Parking',
    reception: 'Reception & Cafe',
    liftstation: 'Lift Station',
    entrance: 'Main entrance to park',
    fireplace: 'Fireplace',
    toilet: 'Toilet',
    wash: 'Bike Wash',
    tools: 'Tools',
    endal: 'Endal lava',
    teerisaari: 'Teerisaari lava',
    kalasuntti: 'Kalasuntti lava',
    vicksback: 'Vicksbäck lava'
    },
    box: {
      location: {
      title: 'Location',
      description: 'Teijontie 345, 25570 Teijo'
      },
      phone: {
      title: 'Via phone',
      valtteri: 'Valtteri Vainio - 041 313 3051',
      jani: 'Jani Salminen - 041 314 7147'
      },
      email: {
      title: 'Email',
      description: 'asiakaspalvelu@meriteijobikepark.com'
      }
    }
  },

  trails: {
  navbartitle: 'Trails',
  ogdh: 'High speed downhill trail with drops and gap jumps running down the steepest parts of MTBP.',
  hanger: 'High speed freeride trail with unique sketchy features and challenging jumps. Coming 2022 August.',
  valhalla: 'This gem requires some pedaling. Once you get the job done, you will be rewarded with amazing scenery and unique natural features.',
  dipnrip: 'Very rocky trail full of demanding and tight sections. Watch out, make a small mistake and youre out!',
  pipeline: 'Technical downhill trail with a little bit of everything, minus the gap jumps! Steep stuff, rocky sections and speedy descends.',
  hybrid: 'Flow"ish" mixed trail with everything from machine built flow features to rocky tech sections and challenging jumps. Fun fact: First feature ever built in MTBP was built on the Hybrid track. It was also the first trail with a name.',
  otb: 'Machine & hand built Flow trail zig-zaging through beautiful forest scenery. Black freeride-line variant starts from the middle section.',
  jcb: 'Opening in June or August of 2022. Freeride track',
  srock: 'Steep & techy, mostly singletrack trail with beautiful natural rock formations and features throughout.',
  vacation: 'Almost a kilometer long singletrack through the woods and around a pond. Some handbuilt sections to increase flow, but mostly natural. Trail was made easier for 2021 with beginners in mind.',
  safari: 'Machine built flow trail with unique wooden features, table top jumps and easy drops. Ideal for beginners.',
  snake: 'Machine built wide flow trail with big berms and easy pumps. Suitable for beginners.',
  jordie: 'Full of unique hand built features. Big FMX Ramp jump is a new feature for Jordie in 2021. Along with many other smaller features and upgrades.',
    classes: {
    title: 'Difficulty',
      green: {
      title: 'Very Easy',
      description: 'No surprises, a trail suited for beginners.'
      },
      blue: {
      title: 'Easy',
      description: 'Easy elements and a slow pace. Great for beginners also.'
      },
      red: {
      title: 'Intermediate',
      description: 'Red trails have challenging elements. Fortunately, most challenging ones you can skip and go around.'
      },
      black: {
      title: 'Hard',
      description: 'Demanding trail. Here you will need to keep up your speed. Make sure you know what lies ahead when riding a black trail.'
      },
      hardcore: {
      title: 'HC',
      description: 'Pro-trail, professionals only.'
      }
    }
  },
  
  openingtimes: {
  navbartitle: 'Schedule',
  title: 'Fall openingtimes - Week 32->',
    monday: {
    title: 'Monday - ',
    status: 'Closed'
    },
    tuesday: {
    title: 'Tuesday - ',
    status: 'Closed'
    },
    wednesday: {
    title: 'Wednesday - ',
    status: 'Closed'
    },
    thursday: {
    title: 'Thursday - ',
    status: '16-20'
    },
    friday: {
    title: 'Friday - ',
    status: '16-20'
    },
    saturday: {
    title: 'Saturday - ',
    status: '11-17'
    },
    sunday: {
    title: 'Sunday - ',
    status: '11-17'
    }
  },

  events: {
  navbartitle: 'Events',
  title: 'Events',
  description: 'Click on the event for additional information!',
  event1: {
    title: 'Soft Openings',
    secondtitle: '18.5. & 25.5.2024',
    link: '',
    description: 'Lifts open 10-16. We try to open maximum amount of tracks for these dates. Tracks currently open: Safari, OTB, Hanger, Patteri, Hybrid, Pipeline, OG DH, Jordie, Vacation, Dip n Rip, Valhalla, S-Rock, Rölli. Season passes are valid at official opening. Day tickets are 25€. Ticketsale is at our lifts, since rental shop and kiosk are closed. Plenty of parking space is also near the liftstation.',
    },
    event2: {
    title: 'MTBP 2024 Opening',
    secondtitle: '1.6.2024',
    link: '',
    description: 'Biking season is officially starting in Teijo at first saturday of June! Come and check out what we have in store for the new season. Lots of new variations and upgrades for our tracks await you!'
    },
    event3: {
    title: 'Suomi DH',
    secondtitle: '8.-9.6.2024',
    link: 'LISÄTIETOA TAPAHTUMASTA',
    description: 'First Finnish DH-Cup race will be held in Meri-Teijo. Downhill-race will be ridden on a fresh track that was built last season. Exciting and challenging track features even more line choices and options this year. This means exciting times for spectators aswell!'
    },
    event4: {
    title: 'Legit Weekend',
    secondtitle: '11.-12.6.2022 by PussCamp',
    link: 'Pusscamp.com',
    description: 'Valmennusviikonloppu! Valmentajina Elise Kulmala, Petteri Leivo, Juuso Mäenpää, Leo Kokkonen sekä Jasmin Kansikas. Ryhmistä lisää PussCamp verkkosivuilla, linkki alla. Lippuja rajoitettu määrä. Näytteilleasettelijoina mm. Trek, Canyon, Pole, Diamond Bikes & Liv Bikes.'
    },
    event5: {
    title: 'Legit Weekend - Valmennusviikonloppu',
    secondtitle: '21.-22.8.2021',
    link: 'Pusscamp',
    description: 'Lauantaille tällä hetkellä varattu kolme kurssia -> Hissipyöräilyn perusteet klo 10:00-12:00, kokeneemmille klo 12:30-14:30, sekä hypyt, dropit & mutka-ajo klo 15-18 kurssi. Valmentajana itse Elise Kulmala! Liput ja lisätietoja Puss Campin verkkokaupasta:'
    },
    event6: {
    title: 'Weekday deals! - Every Thursday & Friday',
    secondtitle: 'from 6.6.2024',
    description: 'We offer cheap prices for rentals every thursday & friday, for everyone. 2H pack which consists of downhill bike, bike park ticket and full protective gearset is just 50€. 3H is 65€ & 4H is 75€. We recommend you to reserve the gear you need through email asiakaspalvelu@meriteijobikepark.com'
    },
    event7: {
    title: '2022 Kausikorttien ennakkomyynti alkaa',
    secondtitle: '6.8.2021',
    description: '2022 Kausikortit alennettuun hintaan 159€ (norm. 289€). Tämän halvemmalla ei ensi kauden kausikortti irtoa. Varmista paikkasi tulevan kauden tohinoihin edullisesti!'
    },
    event8: {
    title: 'Ruska Race 2021',
    secondtitle: '24.10.2021',
    description: 'Kilpailussa ajetaan kolme(3) eri erikoiskoetta 2-4 kertaa, joista kaksi nopeinta aikaa per erikoiskoe otetaan huomioon ajanotossa. Kilpailun osanottajamäärä on rajattu 48 kilpailijaan. Lisätietoja ja ilmoittautuminen linkistä',
    link: 'Lisätietoja ja ilmoittautumiseen tästä'
    },
    event9: {
    title: 'Kauden päätös 2021',
    secondtitle: '30.-31.10.',
    description: 'Kauden päättäjäis viikonloppuna ajetaan normaalien aukioloaikojen mukaan. Yhdessäoloa ja ajoa kauden viimeisenä viikonloppuna! Tervetuloa!',
    link: 'Facebook'
    },
    event10: {
    title: 'Ansa ja Kuoppa Freeride Sessions',
    secondtitle: '6.7.2024',
    description: 'Freeride Sessions are back again this year!',
    link: 'Freeridecup.fi'
    },
    event11: {
    title: 'MTBRally Gravity Cup 23 - South 1/3',
    secondtitle: '10.-11.6.2023',
    description: 'First Gravity Cup race will be held in Meri-Teijo. Signing up happens at register.mtbrally.com app. Payment amount are compensated from children to adult classes. More information from the event from the link below.',
    link: 'Mtbrally.com'
    },
    event12: {
    title: 'MTB Rally Weekend',
    secondtitle: '14.-15.9.2024',
    description: 'During the summer of 2024, we are hosting five race weekends. The season kicks off at the Fiskars Village Bike Expo, featuring traditional MTB enduro. The main series begins in June! Exciting new additions include the Slopestyle Cup and a two-day downhill camp in Iso-Syöte!',
    link: 'MTBRally Kotisivut'
    },
    event13: {
    title: 'Bike Park',
    secondtitle: '19.8.2023',
    description: 'Day ticket is only 25€. Prices for rentals every wednesday, for everyone. 2H pack which consists of downhill bike, bike park ticket and full protective gearset is just 50€. 3H is 65€ & 4H is 75€. We recommend you to reserve the gear you need through email asiakaspalvelu@meriteijobikepark.com'
    }
  },

  partners: {
  navbartitle: 'Partners',
  meriteijoski: 'Teijo Ski & Action',
  title: 'Partners',
    partner1: {
    title: 'Bikeshop Turku',
    secondtitle: 'Bike Specialist in Turku',
    link: 'Website',
    description: 'Bikeshop.fi is the most versatile, fastest and trustworthy biking webstore in Finland. Bikeshop.fi was formed in 2009, but they have a more traditional background from eight decades in Turku. Bikeshop.fi is Ajopyörä, Turku and Ajopyörä, Turku = Bikeshop.fi'
    },
    partner2: {
    title: 'HHCMB',
    secondtitle: 'Club specialized in mountain biking',
    link: 'Website',
    description: 'Habanero Mountainbikers was formed in 2003 by mainly focusing on mountain biking. However they support all subspecies of biking. Most popular ones in the club are Marathon (XCM), Enduro, Downhill, Cross-Country (XCO) and Road Biking.'
    },
    partner3: {
    title: 'Teijo Ski & Action',
    secondtitle: 'Ski & Action Centre',
    link: 'Website',
    description: 'Teijo Ski & Action offes many activities throughout the year. When winter arrives, bike park turns into a ski center. Idyllic ironwork villages and a center right by the national park serves as one of the most versatile ski centres in southern Finland. Only a short ride away from Salo, Turku and Helsinki.'
    },
    partner4: {
    title: 'Fillaritalli',
    secondtitle: 'Bike service, wherever you are.',
    link: 'Website',
    description: 'Fillaritalli is the easiest and most handy bike service near Turku. We fix and service bikes with the right price in mind - with years of professional experience.'
    },
    partner5: {
    title: 'Puss Camp',
    secondtitle: 'Training and Womens MTB Camp',
    link: 'Website',
    description: 'Puss Camp offers different kind of training and events for mountain biking and downhill riding. They offer events for groups, bike park training, and more. Main event of the year is only for women with different classes for DH-, Enduro-, XC-, and E-Bike riding.'
    }
  },

  info: {
  navbartitle: 'Info',
  title: 'Did u say Teijo? What is mountain biking? Or downhill? How do I start?',
  title2: 'Is your MTB Terminology rusty? Look no further!',
    mtbp: {
    title: 'Meri-Teijo Bike Park',
    description: 'MTBP or, Meri-Teijo Bike Park, is a hobby-oriented, mountainbike specialized cycling service located right next to Teijo National Park. Bike park has tracks built for everyone and every skill level. Teijo National Park offers beautiful nature and exciting trails, but mountain biking requires quality bikes and equipment for you to get the proper experience. From our rental services you can find bikes / e-bikes and required gear to ride safely. We also arrange mountain bike trips with guide or without and activities for groups.'
    },
    liikuntaetu: {
    title: 'Couture Benefits',
    description: 'Do you want to use couture benefits as paying method for our services? We have Smartum, Edenred, ePassi & Eazybreak. Only electric use via app. You can use this method for all products except rental reservations from the website. This payment method for rentals is available only locally at our reception. If you want to use couture benefits for trainings, first make sure there´s a spot open for that training before paying.'
    },
    teijo: {
    title: 'Historical Ironworks and Finnish Archipelago',
    description: 'In the area of Teijo’s ironwork villages there were once three ironworks running: Mathildedal, Teijo and Kirjakkala, which all are located within few kilometers radius from each other by the sea. Kirjakkala and Teijo’s ironworks were founded in 1686 and Mathildedal was founded in 1852. Together they form culturohistorical places which contain stories of Finnish ironworking phases and ironworking communities and their structure. Nowadays most of the buildings are privately owned with companies providing various types of tourism, cultural, and industrial activities and services.'
    },
    teijonkansallispuisto:{
    title: 'Teijo National Park',
    description: 'National Park of Teijo is the youngster in Finlands National Park family. Breathtaking wilderness in the middle of Finland Proper’s culture. Almost every aspect of southern Finlands nature is present here in Teijo: Even during a shorter trip you can walk on rocky cliffs, see beautiful lakes, cross the swamps on duckboards and feel the sea next to you.',
    link: ''
    },
    food: {
    title: 'Food and Lodging',
    description: 'During 2022 we will have a cafe at our reception. Small snacks, dry food, fruit and convenience food. If you get real hungry there are great restaurants and lodges within few kilometres from us. Teijo & Mathildedal offer services even for those, who want to stay in the area for longer. Check more information here -> ',
    link: 'Visit Mathildedal',
      visitmathildedal: {
      title: 'Mathildedal',
      description: 'Hyväntuulisessa ja eläväisessä kylässä järjestetään konsertteja, keikkoja, taidenäyttelyitä, teatteria, tapahtumia ja monenlaisia juhlia läpi vuoden. 1800-luvun ruukkimiljöö kätkee sisälleen modernin kyläyhteisön, jonka 130 asukkaasta moni on yrittäjiä. Vanhat tehdasrakennukset, sydämellinen palvelu ja vehreä ympäristö tarjoavat elämyksiä, rauhaa ja romuromanttista kauneutta kaikille aisteille!',
      link: 'Village has its own website here',
      }  
    },
    vakuutukset: {
    title: 'How to start as a beginner? How to use the lift? How about insurance?',
    description: 'We use T-Bar lifts which are familiar from skiing. Kids bike park has a carpet lift. You can enter these lifts through gates with SnowCards. We inform beginners to try T-Bar lifts by placing the T-Bar under the seat of the bike. Our crew members help you out while entering the lift. We encourage beginners to try the sport. It may feel intimidating at first, but it is not as crazy as it seems! We are glad to assist with the lift and offer private training, too. If you are interested please contact us and lets make an appointment! It is highly recommended to get an insurance. Riding Bike Park trails is on your own responsibility. We follow conditions by SHKY. \n Get comprehensive here -> ',
    link: 'Ski.fi'
    },
    dh: {
    title: 'DH - Downhill',
    description: 'In Downhill racing you fight against the clock. You try to ride down the track as fast as possible. Tracks include steep, rough terrain that often features jumps, drops, rock gardens and other obstacles. Almost every bike park has some DH-tracks where you can test yourself. We offer you all the protective gear to get comfortable for breaking your limits. Heavy protection gear doesnt slow you down because the lift takes you up. DH bikes have long travel in suspensions so they are quite heavy and only meant for riding downhill.'
    },
    enduro: {
    title: 'Enduro',
    description: 'Enduro is the most versatile genre of mountain biking. In enduro, you combine speed and technicality of DH and stamina needed in Cross country (XC). This may be the real reason behind the success of enduro. Enduro bikes are like all-around bikes, they are capable for heavy downhill riding and work just as well in cross country riding.'
    },
    xc: {
    title: 'XC - Cross country',
    description: 'Cross country cycling is the most traditional genre of mountain biking. Cross country is defined by the terrain on which it is performed. XC courses and trails consist of a mix of rough forest paths and singletrack (also referred to as doubletrack depending on width), smooth fireroads, and even paved paths connecting other trails. Until recently cross country trails were deemed "easy" or "intermediate", due to the concept that this discipline of mountain biking relies more on physical prowess than technical ability. XC bikes are made to be very light.'
    },
    hissipyora: {
    title: 'Lift assisted mountain biking',
    description: 'Lift assisted mountain biking is quite a new thing in Finland. Our goal is to create a fun activity where the whole family can join. Just like skiing in winter! Starting mountain biking is not so hard as people tend to think, especially in bike parks who put work in beginner tracks and elements. You use the same lifts as in skiing to get to the top. Meaning of lift is simple: you get to enjoy downhill riding and get maximum reps during the day without pedaling/pushing up. Because if you love downhill you dont want to pedal up!'
    },
    otb: {
    title: 'OTB - Over the bars',
    description: 'When you crash into something solid front wheel ahead, mountain bikers tend to go OTB.'
    },
    berm: {
    title: 'Berm',
    description: 'Berm is a banked turn, which is usually done from soil or wood.'
    },
    bunnyhop: {
    title: 'Bunny hop',
    description: 'You can use bunny hop to get over an obstacle for example. During the jump, first you lift your front wheel up followed by your rear wheel.',
    },
    drop: {
    title: 'Drop',
    description: 'You will encounter drops when riding bike park trails or natural trails. To proceed on the trail the only way is to drop down from an obstacle. You will need speed to engage the drop. They can even be few meters high.'
    },
    hardtail: {
    title: 'Hardtail',
    description: 'Hardtail is a bike without rear suspension.'
    },
    litkutus: {
    title: 'Bunging up!',
    description: 'Fitting a tubeless tire on the rim.'
    },
    singletrack: {
    title: 'Singletrack',
    description: 'Trail that is roughly as wide as a bike.'
    },
    sinkula: {
    title: 'Singlespeed',
    description: 'Bike with only one gear.'
    },
    tubeless: {
    title: 'Tubeless',
    description: 'Tubeless tire setup does not have an inner tube installed.'
    },
    snakebite: {
    title: 'Snakebite',
    description: 'Two holes in the tire caused by the tire folding up against the rim and a sharp object.'
    },
    tyomatkatempo: {
    title: 'On the way to work "Challenge"',
    description: 'The so called ”Playful” on the way to work challenge is when you try to pass other cyclists or in the worst case scenario get passed by other cyclists!'
    },
    taysjousto: {
    title: 'Full Suspension',
    description: 'Bike with suspension in front and rear.'
    },
    taysjaykka: {
    title: 'Rigid',
    description: 'Bike with no suspension.'
    },
    vapaaratas: {
    title: 'Freehub',
    description: 'Freehub is part of the wheel hub, which lets the wheel to spin when your pedals are not spinning.'
    },
    stemmi: {
    title: 'Stem',
    description: 'Holds the handlebar in place.'
    },
    rihtaus: {
    title: 'Truing',
    description: 'Straightening the rim by tightening the spokes in the rim.'
    },
    sukkikset:{
    title: 'Lycra',
    description: 'Tight riding pants.'
    },
    mamil:{
    title: 'MAMIL',
    description: 'Middle Aged Men In Lycra.'
    }
  },

  basket: {
  title: 'Shopping Cart',
  basketIcon: 'Cart',
  cashIcon: 'Checkout',
    basketcontent: {
    title: 'Cart contents',
    continueshoppingbutton: 'Continue shopping',
    product: 'Product',
    price: 'Price',
    count: 'Count',
    total: 'Total',
    value: '0 Eur',
    cashoutbutton: 'Cash out >',
    emptyBasket: 'Your cart is empty'
    },
    basketform: {
    title: 'Checkout',
    subTitle: 'Customer info',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-Mail',
    phone: 'Phone',
    country: 'Country',
    countrySelect: 'CHOOSE COUNTRY',
    address: 'Address',
    postalCode: 'Postcode',
    postOffice: 'City',
    deliveryTerms: 'You must accept our',
    terms: 'terms of delivery',
    confirmButton: 'Confirm Order',
    shopConditions: conditionsTextEng
    },
    basketSuccess: {
    title: 'Thank you for your purchase',
    returnButton: '< Return Home',
      customer: {
      title: 'Customer information',
      firstName: 'First Name',
      surName: 'Last Name',
      date: 'Date',
      paymentMethod: 'Payment Method'
      },
      order: {
      title: 'Order information',
      id: 'Order ID',
      product: 'Product',
      amount: 'Amount',
      price: 'Price',
      finalprice: 'Total'
      }
    }
  }
};

export default eng;