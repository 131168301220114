import React, { Component } from 'react';
import localization from '../localization/localization';
import CartItemList from './CartItemList';
import CartComponentHeader from './CartComponentHeader';
import CartComponentContactForm from './CartComponentContactForm';
import { NavLink } from 'react-router-dom';
import './CartComponent.css';

class CartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backGroundImg: process.env.PUBLIC_URL + '/media/verkkokauppa.jpg',
      selectedTab: 'cart'
    }
    this.changeTab = this.changeTab.bind(this);
  };

  changeTab(select) {
    this.setState({
      selectedTab: select
    });
  }
  
  render() {
    const { onlineStore: { basket: { count } } } = this.props;
    return (
      <div className="shop-body">
        <div style={{backgroundImage: `url(${this.state.backGroundImg})`}} className="shop-background-img"></div>
        <div className="shop-item-container">
          <CartComponentHeader 
            changeTab={this.changeTab} 
            selectedTab={this.state.selectedTab}
            onlineStore={this.props.onlineStore}
          />
          { this.state.selectedTab === 'cart' ? 
            <div className="shop-item-container-body">
              <h1>{localization.basket.basketcontent.title}</h1>
              <NavLink className="basket-continue-shopping" to="/shop">{'<'} {localization.basket.basketcontent.continueshoppingbutton}</NavLink>
              <CartItemList
                onlineStore={this.props.onlineStore} 
                addOnlineStoreItem={this.props.addOnlineStoreItem}
                removeStoreItem={this.props.removeStoreItem}
              />
              <button className='shop-item-container-body-button'  disabled={count.items === 0} onClick={()=> this.changeTab('contact')} >{localization.basket.basketcontent.cashoutbutton}</button>
            </div> : 
            <div className="shop-item-container-body">
              <CartComponentContactForm 
                history={this.props.history} 
                setRedirectUrl={this.props.setRedirectUrl} 
                onlineStore={this.props.onlineStore} 
                setContactInfo={this.props.setContactInfo}/>
            </div> }
          </div>
      </div>
    );
  }
};

export default CartComponent;