import React from 'react';
import ShopTrainingListItem from './ShopTrainingListItem';
import { getStoreItemByProductType } from '../../shopHelper/shopHelper';
import './ShopList.css';

const ShopTrainingList = (props) => {
  return (
    <div className="shop-list-body">
      <div className="shop-list">
        <ShopTrainingListItem
        addStoreItem={props.addStoreItem}
        removeStoreItem={props.removeStoreItem}
        onlineStore={props.onlineStore}
        product={getStoreItemByProductType('BEGINNER_TRAINING')}
        disabled={true}/>
        <ShopTrainingListItem
        addStoreItem={props.addStoreItem}
        removeStoreItem={props.removeStoreItem}
        onlineStore={props.onlineStore}
        product={getStoreItemByProductType('BEGINNER_TRAINING_2')}
        disabled={true}/>
        <ShopTrainingListItem
        addStoreItem={props.addStoreItem}
        removeStoreItem={props.removeStoreItem}
        onlineStore={props.onlineStore}
        product={getStoreItemByProductType('ADVANCED_TRAINING')}
        disabled={true}/>
      </div>
    </div>
  );
};

export default ShopTrainingList;

