import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import AppContainer from './AppContainer';
import { Provider } from 'react-redux';
import store from './store';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-156521317-1');

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <AppContainer />
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));
