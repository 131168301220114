import React, { Component } from 'react';
import localization from '../localization/localization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CartComponentHeader.css';

class CartComponentHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  };
  
  render() {
    const { selectedTab, onlineStore: { basket: { count } } } = this.props;
    return (
      <div className="shop-item-container-header">
       <h3>{this.props.selectedTab === 'contact' ? localization.basket.basketform.title : localization.basket.title}</h3>
        <i onClick={() => this.props.changeTab('contact')} className={count.items ? selectedTab === 'contact' ? 'active' : '' : 'disabled'}>
          <FontAwesomeIcon className="contact-icon" icon="address-book"/>
          <p>{localization.basket.cashIcon}</p>
        </i>
        <i onClick={() => this.props.changeTab('cart')} className={selectedTab === 'cart' ? 'active' : ''}>
          <FontAwesomeIcon className="cart-icon" icon="shopping-cart"/>
          <p>{localization.basket.basketIcon}</p>
        </i>
      </div>
    );
  }
};

export default CartComponentHeader;