import React, { useState } from 'react';
import { centsToEur } from '../../helpers/helpers';
import localization from '../localization/localization';
import './ShopListItem.css';
import ShopPopUp from './ShopPopUp';

const ShopServiceListItem = (props) => {
  const { product, addStoreItem, removeStoreItem, disabled } = props;
  const [showPopup, togglePopup] = useState(false);

  const addBasketItem = (show) => {
    addStoreItem(product.id);
    togglePopup(show);
  }

  return (
    <div className={ disabled ? 'shop-list-column disabled' : 'service-list-column'}>
      <div className="shop-list-column-img-container">
      <img alt="shop-list-column-img" className="shop-list-column-img" src={process.env.PUBLIC_URL + product.img}></img>
      {disabled && <p className="ticket-list-no-sale">{localization.shop.products.Services.notForSale}</p>}
      </div>
      <div className="shop-list-column-title">{product.title}</div>
      <div className="shop-list-column-text">{product.description}</div>
      <div className="shop-list-form">
        <p className="shop-list-column-price">{localization.shop.products.Services.price} {centsToEur(product.price)}€ / 2H</p>
        <button disabled={disabled} onClick={() => addBasketItem(true)} className="shop-list-column-button">{localization.shop.products.Services.button}</button>
      </div>
      { showPopup ?
        <ShopPopUp
          onlineStore={props.onlineStore}
          product={product}
          closePopup={togglePopup}
          addStoreItem={addStoreItem}
          removeStoreItem={removeStoreItem}
        />
        : null
      }
    </div>
  );
}

export default ShopServiceListItem;