import React from "react";
import localization from "../localization/localization";
import ShopTabs from "./ShopTabs";
import CoutureBenefits from "./CoutureBenefits";
import "./Shop.css";

const Shop = (props) => {
  const backGroundImg = process.env.PUBLIC_URL + "/media/verkkokauppa.jpg";

  return (
    <div className="online-shop-body">
      <div
        className="online-shop-parallax-img"
        style={{ backgroundImage: `url(${backGroundImg})` }}
      >
        <h1>{localization.shop.title}</h1>
      </div>
      <div className="online-shop-parallax-body">
        <div className="online-shop-product-header">
          <h3>{localization.shop.productTitle}</h3>
        </div>
        <div className="online-shop-product-body">
          <ShopTabs
            language={props.language}
            rentalProducts={props.rentalProducts}
            onlineStore={props.onlineStore}
            addStoreItem={props.addStoreItem}
            addProductToBasket={props.addProductToBasket}
            removeStoreItem={props.removeStoreItem}
            setReservation={props.setReservation}
            addOnlineStoreItem={props.addOnlineStoreItem}
          />
        </div>
        <div className="online-shop-announcer">
          <CoutureBenefits />
        </div>
      </div>
    </div>
  );
};

export default Shop;
