import React, { useState } from "react";
import localization from "../localization/localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShopPopUp from "./ShopPopUp";
import "./ShopListItem.css";
import { centsToEur } from "../../helpers/helpers";

const ShopTicketListItem = (props) => {
  const { product, addOnlineStoreItem, removeStoreItem, disabled, language } =
    props;
  const [showPopup, togglePopup] = useState(false);

  const productProperty = product.properties && product.properties[0];

  const addBasketItem = () => {
    addOnlineStoreItem({
      id: productProperty.id,
      title: productProperty.name[language],
      description: productProperty.description[language],
      price: productProperty.price,
      amount: 1,
      vat: productProperty.vat,
      product_code: productProperty.product_code,
      type: product.type
    });
    togglePopup(true);
  };

  return productProperty ? (
    <div
      className={disabled ? "shop-list-column disabled" : "shop-list-column"}
    >
      <div className="shop-list-column-img-container">
        <FontAwesomeIcon className="shop-list-icon" icon="ticket-alt" />
        {disabled && (
          <p className="shop-list-no-sale">
            {localization.shop.products.Tickets.notForSale}
          </p>
        )}
      </div>
      <div className="shop-list-column-title">
        {productProperty.name[language]}
      </div>
      <div className="shop-list-column-text">
        {productProperty.description && productProperty.description[language]}
      </div>
      <div className="shop-list-form">
        <p className="shop-list-column-price">
          {localization.shop.products.Tickets.price} {centsToEur(productProperty.price)}€
        </p>
        <button
          disabled={disabled}
          onClick={addBasketItem}
          className="shop-list-column-button"
        >
          {localization.shop.products.Tickets.button}
        </button>
      </div>
      {showPopup ? (
        <ShopPopUp
          language={language}
          onlineStore={props.onlineStore}
          product={productProperty}
          closePopup={togglePopup}
          addOnlineStoreItem={addOnlineStoreItem}
          removeStoreItem={removeStoreItem}
        />
      ) : null}
    </div>
  ) : null;
};

export default ShopTicketListItem;
