import React, {Component} from 'react'
import localization from '../localization/localization'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMountain } from '@fortawesome/free-solid-svg-icons'
import './CardComponent.css'

class Column extends Component {
    constructor(props){
        super(props)
        this.state = {
            rentalImg: process.env.PUBLIC_URL + '/media/vuokraamo_img.jpg',
            downhillImg: process.env.PUBLIC_URL + '/media/hissipyoraily_img.jpg',
            groupImg: process.env.PUBLIC_URL + '/media/yrityksille_img.jpg',
            mountainImg: process.env.PUBLIC_URL + '/media/maastopyoraily_img.jpg'
        }
    }

   render() {
    library.add(faMountain)
    return (
      <div className="card-row-body">
        <div className="card-row-title">{localization.home.services.title}</div>
        <div className="card-row">
          <div className="card-row-column">
            <img className="card-row-column-img" alt="bgi" src={this.state.downhillImg}></img>
            <div className="card-row-column-title">{localization.home.services.hissipyoraily.title}</div>
            <div className="card-row-column-text">{localization.home.services.hissipyoraily.text}</div>
            <Link to="/hissipyoraily">
                <button className="card-row-column-button">{localization.home.services.hissipyoraily.button}</button>
            </Link>
          </div>
          <div className="card-row-column">
            <img className="card-row-column-img" alt="bgi" src={this.state.mountainImg}></img>
            <div className="card-row-column-title">{localization.home.services.maastopyoraily.title}</div>
            <div className="card-row-column-text">{localization.home.services.maastopyoraily.text}</div>
            <Link to="/maastopyoraily">
                <button className="card-row-column-button">{localization.home.services.maastopyoraily.button}</button>
            </Link>
          </div>
          <div className="card-row-column">
            <img className="card-row-column-img" alt="bgi" src={this.state.groupImg}></img>
            <div className="card-row-column-title">{localization.home.services.yrityksille.title}</div>
            <div className="card-row-column-text">{localization.home.services.yrityksille.text}</div>
            <Link to="/yrityksille">
                <button className="card-row-column-button">{localization.home.services.yrityksille.button}</button>
            </Link>
          </div>
          <div className="card-row-column">
            <img className="card-row-column-img" alt="bgi" src={this.state.rentalImg}></img>
            <div className="card-row-column-title">{localization.home.services.vuokraamo.title}</div>
            <div className="card-row-column-text">{localization.home.services.vuokraamo.text}</div>
            <Link to="/vuokraamo">
                <button className="card-row-column-button">{localization.home.services.vuokraamo.button}</button>
            </Link>
        </div>
      </div>
    </div>
    )
  }
}

export default Column