import React, {useState} from 'react';
import '../cart/Conditions.css';
import localization from '../localization/localization';

const CoutureBenefits = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openPopUp = () => setIsOpen(!isOpen);

  return (
    <div className="shop-conditions-container">
      <div className="popup"> {localization.shop.announcer}
        <u onClick={openPopUp}> {localization.info.liikuntaetu.title}</u>
        <span className={isOpen ? "popuptext show" : "popuptext"}>{localization.info.liikuntaetu.description}</span>
      </div>
    </div>
  );
}

export default CoutureBenefits;