export const conditionsText = 
`Javaction Oy toimitusehdot Voimassa 20.01.2020 alkaen
Yleistä verkkokaupasta
Verkkokaupan tuotteita myy Javaction Oy, Y-Tunnus 2995842-3
Myymme tuotteita Suomeen ja EU-alueelle. Tuotteiden hinnat sisältävät arvonlisäveron. Pidätämme oikeuden hintojen muutoksiin.

Tilaaminen
Tuotteet tilataan pääsääntöisesti internetin välityksellä osoitteesta www.meriteijobikepark.com/shop ostoskorin kautta.
Kaikki tilaukset vahvistetaan sähköpostilla, jossa ilmoitetaan tilauksen hinta, toimituskulut sekä tilatut tuotteet. Tilausvahvistuksen saaminen edellyttää sähköpostiosoitteen ilmoittamista tilauksen yhteydessä.
Sitoudut jokaisen tilauksen yhteydessä voimassa oleviin toimitusehtoihin.
Voit ostaa hissilippusi etukäteen verkkokaupasta. Fyysisen lipukkeesi saat meiltä ostotositetta vastaan aukioloaikoina. Kausikortit ovat henkilökohtaisia. Kausikorteilla tai päivälipuilla ei ole palautusoikeutta. Kausikortin väärinkäytöstä seuraa kausikortin menetys.

Tilaus- ja maksuvahvistus
Lähetämme sähköpostitse tilausvahvistuksen, josta näet
•	Tilauksen tuotelistauksen
•	Laskun loppusumman
Maksutavat
Tuotteet ja niiden postikulut maksetaan tilauksen yhteydessä. Verkkokaupassa voidaan maksaa seuraavilla maksutavoilla: Nordea, Osuuspankki, Danske Bank, Ålandsbanken, Handelsbanken, S-Pankki, Aktia, Säästöpankki, POP Pankki, Oma Säästöpankki, Visa, MasterCard, American Express, Diners Club, JCB, Collector lasku- ja osamaksutavat, Jousto lasku- ja osamaksutavat, Paytrail-tili. Maksaminen tapahtuu Paytrail Oyj:n sivuston kautta.
——————–
Maksupalveluntarjoaja
Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj www.paytrail.com (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.
Paytrail Oyj, Y-Tunnus: 2122839-7
Innova 2
Lutakonaukio 7
40100 Jyväskylä
Puhelin 0207 181830
www.paytrail.com
——————–
Payment Service Provider
Paytrail Plc acts as a collecting payment service provider and is an authorized Payment Institution. Paytrail Plc will be shown as the recipient on your bank or credit card statement. Paytrail Plc will forward the payment to the merchant. For reclamations, please contact the website the payment was made to.
Paytrail Plc
Innova 2
Lutakonaukio 7
40100 Jyväskylä
Phone +358 207 181 830
Business ID 2122839-7
——————–
Vaihto- ja palautusehdot
Verkkokaupasta ostetuilla tuotteillamme ei ole vaihto- tai palautusoikeutta.

Muuta
Pidätämme oikeuden muuttaa toimitusehtojamme. Sinun on ennen tilaustasi tutustuttava kulloinkin voimassa oleviin toimitusehtoihin.
Rekisteriseloste
Tässä verkkokaupassa noudatetaan Javaction Oy:n rekisteriselostetta.`;