import { 
  FETCH_RENTAL_PRODUCTS_REQUEST,
  FETCH_RENTAL_PRODUCTS_SUCCESS,
  FETCH_RENTAL_PRODUCTS_ERROR,
} from '../actions/actionTypes';

const initialState = {
    items: [],
    isFetching: false,
    error: null
};

export const rentalProducts = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RENTAL_PRODUCTS_REQUEST: 
      return {
        ...state,
        isFetching: true
      }
    
    case FETCH_RENTAL_PRODUCTS_SUCCESS: 
      return {
        ...state,
        isFetching: false,
        items: action.payload
      }
    
    case FETCH_RENTAL_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.error
      }

    default:
      return state;
  };
};