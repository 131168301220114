import React, { Component } from 'react';
import localization from '../localization/localization';
import './Info.css';

export default class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teijokansallispuisto: process.env.PUBLIC_URL + '/media/teijokansallispuisto.jpg',
      visitmathildedal: process.env.PUBLIC_URL + '/media/visitmathildedal.jpg',
    }
  }

  /*<div className="info-body-container">
            <div className="info-title-2"><h1>{localization.info.title2}</h1></div>
            <li>
            <p className="info-list">{localization.info.dh.title}</p>
            <p className="info-description">{localization.info.dh.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.enduro.title}</p>
              <p className="info-description">{localization.info.enduro.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.xc.title}</p>
              <p className="info-description">{localization.info.xc.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.berm.title}</p>
              <p className="info-description">{localization.info.berm.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.bunnyhop.title}</p>
              <p className="info-description">{localization.info.bunnyhop.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.drop.title}</p>
              <p className="info-description">{localization.info.drop.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.litkutus.title}</p>
              <p className="info-description">{localization.info.litkutus.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.otb.title}</p>
              <p className="info-description">{localization.info.otb.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.snakebite.title}</p>
              <p className="info-description">{localization.info.snakebite.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.tyomatkatempo.title}</p>
              <p className="info-description">{localization.info.tyomatkatempo.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.taysjousto.title}</p>
              <p className="info-description">{localization.info.taysjousto.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.hardtail.title}</p>
              <p className="info-description">{localization.info.hardtail.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.taysjaykka.title}</p>
              <p className="info-description">{localization.info.taysjaykka.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.vapaaratas.title}</p>
              <p className="info-description">{localization.info.vapaaratas.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.stemmi.title}</p>
              <p className="info-description">{localization.info.stemmi.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.rihtaus.title}</p>
              <p className="info-description">{localization.info.rihtaus.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.sukkikset.title}</p>
              <p className="info-description">{localization.info.sukkikset.description}</p>
            </li>
            <li>
              <p className="info-list">{localization.info.mamil.title}</p>
              <p className="info-description">{localization.info.mamil.description}</p>
            </li>
          </div> */

  render() {
    return (
      <div className="info-body">
        <div className="info-body-container">
          <div className="info-title"><h1>{localization.info.title}</h1></div>
          <li>
            <p className="info-list">{localization.info.mtbp.title}</p>
            <p className="info-description">{localization.info.mtbp.description}</p>
            <p className="info-description">{localization.info.mtbp.description2}</p>
          </li>
          <li>
            <p className="info-list">{localization.info.liikuntaetu.title}</p>
            <p className="info-description">{localization.info.liikuntaetu.description}</p>
          </li>
          <li>
            <p className="info-list">{localization.info.food.title}</p>
            <p className="info-description">{localization.info.food.description}<a href="https://www.visitmathildedal.fi/">{localization.info.food.link}</a></p>
          </li>
          <li>
            <p className="info-list">{localization.info.vakuutukset.title}</p>
            <p className="info-description">{localization.info.vakuutukset.description}<a href="https://www.ski.fi/info/sopimusehdot/bikepark-sopimusehdot/">{localization.info.vakuutukset.link}</a></p>
          </li>
          <li>
            <p className="info-list">{localization.info.teijo.title}</p>
            <p className="info-description">{localization.info.teijo.description}</p>
            <div className="info-row">
              <div className="info-row-column">
                <div className="info-column-title">{localization.info.food.visitmathildedal.title}</div>
                <div className="info-column-secondtitle">{localization.info.food.visitmathildedal.secondTitle}</div>
                <div className="info-column-text">{localization.info.food.visitmathildedal.description}</div>
                <a className="info-column-link" href="https://www.visitmathildedal.fi/">{localization.info.food.visitmathildedal.link}</a>
              </div>
              <div className="info-row-column">
                <img className="info-column-img" alt="bgi" src={this.state.visitmathildedal}></img>
              </div>
            </div>
            <br></br>
          </li>
          <li>
            <div className="info-row">
              <div className="info-row-column">
                <div className="info-column-title">{localization.info.teijonkansallispuisto.title}</div>
                <div className="info-column-secondtitle">{localization.info.teijonkansallispuisto.secondTitle}</div>
                <div className="info-column-text">{localization.info.teijonkansallispuisto.description}</div>
                <a className="info-column-link" href="https://www.visitmathildedal.fi/">{localization.info.teijonkansallispuisto.link}</a>
              </div>
              <div className="info-row-column">
                <img className="info-column-img" alt="bgi" src={this.state.teijokansallispuisto}></img>
              </div>
            </div>
          </li>
        </div>
      </div>
    );
  }
}