import React, { useState } from "react";
import localization from "../localization/localization";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CountrySelect from "./CountrySelect";
import Conditions from "./Conditions";
import { createPayment, orderRentalProduct } from "../../api/api";
import CoutureBenefits from "../shop/CoutureBenefits";
import { useNavigate } from "react-router-dom";
import "./CartComponentContactForm.css";

const CartComponentContactForm = (props) => {
  const [state, setState] = useState({
    contact: {},
  });

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const handleChange = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setState({
      ...state,
      contact: {
        ...state.contact,
        [event.target.name]: value,
      },
    });
  };

  const handleSubmit = async () => {
    const { contact } = state;
    const keys = Object.values(contact).filter((value) => value);
    if (Object.keys(contact).length === 9 && keys.length === 9) {
      const isRentalProducts = props.onlineStore.basket.products.some(
        (item) => item.type === "RENTAL"
      );
      if (isRentalProducts) {
        await orderRentalProduct({
          token: props.onlineStore.basket.reservation.reservationToken,
          firstname: contact.firstName,
          lastname: contact.lastName,
          email: contact.email,
          phone: contact.mobile,
          gender: "MALE",
          start_time: props.onlineStore.basket.reservation.startTime,
          end_time: props.onlineStore.basket.reservation.endTime,
          payment_status: "PENDING",
        });
      }

      const products = props.onlineStore.basket.products.map((p) => {
        return {
          productCode: p.product_code,
          units: p.amount,
        };
      });

      const payment = {
        contact: {
          email: state.contact.email,
          firstName: state.contact.firstName,
          lastName: state.contact.lastName,
          phone: state.contact.mobile,
        },
        products: [...products],
      };

      setLoading(true);
      createPayment(payment)
        .then((response) => {
          props.setRedirectUrl(response.data);
          response.data ? navigate("/payment") : navigate("/");
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.warn(error);
        });
    } else {
      alert("Täytä kaikki kentät!");
    }
  };

  return (
    <div className="shop-contact-form-body">
      <h2>{localization.basket.basketform.subTitle}</h2>
      <label>{localization.basket.basketform.firstName}</label>
      <input type="text" name="firstName" onChange={handleChange} />
      <label>{localization.basket.basketform.lastName}</label>
      <input type="text" name="lastName" onChange={handleChange} />
      <label>{localization.basket.basketform.email}</label>
      <input type="text" name="email" onChange={handleChange} />
      <label>{localization.basket.basketform.phone}</label>
      <input type="text" name="mobile" onChange={handleChange} />
      <label>{localization.basket.basketform.country}</label>
      <CountrySelect handleChange={handleChange} />
      <label>{localization.basket.basketform.address}</label>
      <input type="text" name="street" onChange={handleChange} />
      <label>{localization.basket.basketform.postalCode}</label>
      <input type="text" name="postalCode" onChange={handleChange} />
      <label>{localization.basket.basketform.postOffice}</label>
      <input type="text" name="postalOffice" onChange={handleChange} />
      <Conditions handleChange={handleChange} />

      <LoadingButton
        onClick={handleSubmit}
        size="small"
        loading={loading}
        variant="outlined"
        loadingPosition="end"
        endIcon={<SendIcon />}
      >
        {localization.basket.basketform.confirmButton}
      </LoadingButton>
      <CoutureBenefits />
    </div>
  );
};

export default CartComponentContactForm;
