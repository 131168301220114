import React, { useEffect } from "react";
import Intro from "./components/intro/Intro";
import HeaderContainer from "./components/header/HeaderContainer";
import CardInfoComponent from "./components/cardInfo/CardInfoComponent";
import ContactUsComponent from "./components/contactUs/ContactUsComponent";
import CartContainer from "./components/cart/CartContainer";
import ShopContainer from "./components/shop/ShopContainer";
import Trails from "./components/trails/Trails";
import Openingtimes from "./components/openingtimes/Openingtimes";
import Events from "./components/events/Events";
import Partners from "./components/partners/Partners";
import Info from "./components/info/Info";
import RedirectOnUrl from "./components/redirect/RedirectOnUrl";
import Footer from "./components/footer/Footer";
import localization from "./components/localization/localization";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import PaymentSuccessful from "./components/redirect/PaymentSuccessful";
import "./App.css";
import PaymentCancel from "./components/redirect/PaymentCancel";

const App = (props) => {
  const location = useLocation();
  localization.setLanguage(props.utils.language);

  useEffect(() => {
    props.fetchRentalProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <div className="App">
      <HeaderContainer location={location.pathname} />
      <Routes>
        <Route path="/" exact element={<Intro />} />
        <Route
          exact
          path={"/hissipyoraily"}
          element={<CardInfoComponent {...props} />}
        />
        <Route
          exact
          path={"/yrityksille"}
          element={<CardInfoComponent {...props} />}
        />
        <Route
          exact
          path={"/maastopyoraily"}
          element={<CardInfoComponent {...props} />}
        />
        <Route
          exact
          path={"/vuokraamo"}
          element={<CardInfoComponent {...props} />}
        />
        <Route
          exact
          path="/contact"
          element={<ContactUsComponent {...props} />}
        />
        <Route exact path="/cart" element={<CartContainer {...props} />} />
        <Route exact path="/shop" element={<ShopContainer {...props} />} />
        <Route exact path="/trails" element={<Trails {...props} />} />
        <Route path="/openingtimes" element={<Openingtimes {...props} />} />
        <Route path="/events" element={<Events {...props} />} />
        <Route path="/partners" element={<Partners {...props} />} />
        <Route exact path="/info" element={<Info />} />
        <Route
          path="/payment"
          element={
            props.onlineStore.basket.redirect ? (
              <RedirectOnUrl redirectUrl={props.onlineStore.basket.redirect} />
            ) : (
              <Intro />
            )
          }
        />
        <Route exact path="/payment/success" element={<PaymentSuccessful />} />
        <Route exact path="/payment/cancel" element={<PaymentCancel />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
