import React from "react";
import ShopTicketListItem from "./ShopTicketListItem";
import Grid from "@mui/material/Grid";
import "./ShopList.css";

const ShopTicketList = (props) => {
  const { rentalProducts } = props;
  const products =
    rentalProducts.items.filter((prod) => prod.type === "TICKET") || [];

  return (
    <div className="shop-list-body">
      <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 2, md: 1 }}
        columns={{ xs: 1, sm: 6, md: 12 }}
      >
        {products.map((product, index) => (
          <Grid item xs={2} sm={3} md={4} key={index}>
            <ShopTicketListItem
              language={props.language}
              addOnlineStoreItem={props.addOnlineStoreItem}
              removeStoreItem={props.removeStoreItem}
              onlineStore={props.onlineStore}
              product={product}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ShopTicketList;
